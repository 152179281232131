import Breadcrumbs from "../../../components/layouts/breadcrumbs/Breadcrumbs";

import mugs_1 from "../../../resources/img/pages/mugs/mugs_1.jpg";
import mugs_2 from "../../../resources/img/pages/mugs/mugs_3.jpg";
import mugs_3 from "../../../resources/img/pages/mugs/mugs_2.jpg";
import mugs_4 from "../../../resources/img/pages/mugs/mugs_4.jpg";
import mugs_5 from "../../../resources/img/pages/mugs/mugs_5.jpg";
import mugs_6 from "../../../resources/img/pages/mugs/mugs_6.jpeg";
import mugs_7 from "../../../resources/img/pages/mugs/mugs_7.jpeg";
import mugs_8 from "../../../resources/img/pages/mugs/mugs_8.jpeg";

const MugsPage = () => {
  return (
    <div className="mugs">
      <div id="use-cases" className="section about__child">
        <div className="container">
          <Breadcrumbs />
          <div className="section-title-wrapper">
            <h2 className="title section-title has-text-centered dark-text text-bold">
              Мульти-интеллект жобасы.
            </h2>
          </div>
          <div className="content-wrapper tabbed-features">
            <div className="columns is-vcentered">
              <div className="column">
                <div className="navigation-tabs outlined-pills animated-tabs">
                  <div className="tabs is-centered">
                    <ul>
                      <li className="tab-link is-active" data-tab="mugs_1">
                        <a>Шынықсаң – шымыр боласың</a>
                      </li>
                      <li className="tab-link" data-tab="mugs_2">
                        <a>Өнерлі өрге жүзеді</a>
                      </li>
                      <li className="tab-link" data-tab="mugs_3">
                        <a>Нағыз қазақ домбыра</a>
                      </li>
                      <li className="tab-link" data-tab="mugs_4">
                        <a>Робот әлемі</a>
                      </li>
                      <li className="tab-link" data-tab="mugs_5">
                        <a>Көркемсөз байқауы</a>
                      </li>
                      <li className="tab-link" data-tab="mugs_6">
                        <a>Арт</a>
                      </li>
                      <li className="tab-link" data-tab="mugs_7">
                        <a>Өзге елге саяхат</a>
                      </li>
                      <li className="tab-link" data-tab="mugs_8">
                        <a>Шахмат турнирі</a>
                      </li>
                    </ul>
                  </div>
                  <div id="mugs_1" className="navtab-content is-active">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={mugs_1} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Шынықсаң – шымыр боласың</h2>
                          <p className="body-color"></p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Мектебімізде сабақ кестесі бойынша каратэ сабағы аптасына 3 рет
                                өткізіледі. Каратэ сабағының мақсаты - оқушылар бойында
                                отансүйгіштік тәрбиесін күшейту, спортпен тұрақты шұғылдануға тарту,
                                салауатты өмір салтын ұстануды дәріптеу. Оқушыларымыз жүйелі түрде
                                аттестациядан өтеді.
                              </div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Оқушыларымыз каратэден аудандық, облыстық, республикалық
                                чемпионаттарға қатысып, әрдайым жүлделі орындарға ие болуда. Оқу
                                жылының басынан білімгерлеріміз кекушинкай каратэден жасөспірімдер
                                мен жастар арасындағы Алматы облысы чемпионатына, Алматы қалалық
                                чемпионатында, Алматы қаласының біріншілігінде бақтарын сынап,
                                қанжығаларына 10 алтын, 16 күміс, 12 қола медальді байлады. Алдағы
                                уақыттарда да жарыстарға қатысып, оқушылардың спорттық жетістіктерін
                                шыңдау жоспарда.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="mugs_2" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={mugs_2} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Өнерлі өрге жүзеді</h2>
                          <p className="body-color">
                            Оқушыларымыз аптасына 3 рет қозғалыспен дене қимылы арқылы көркем
                            образды бейнелейтін, мәдени сахна өнері – хореография сабағына қатысады.
                            Оқушыларға рухани байлығымызды дәріптеу, шығармашылыққа деген талпынысты
                            арттыру, дамуына қолдау көрсету, би өнерінің кең ауқымда өрбуіне ат
                            салысуын мақсат еткен конкурстарға қатысып, жүлделі орындарға ие болды.
                            Атап айтқанда, халықаралық «Grand Prix Almaty-2023» чемпионаты, «Бери
                            выше» IV халықаралық хореографиялық өнер конкурсы, "Art winter" атты
                            халықаралық конкурс, "FANTASTIC TALENT" фестиваль-конкурсы.
                          </p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="mugs_3" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={mugs_3} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Нағыз қазақ домбыра</h2>
                          <p className="body-color"></p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Мектеп қабырғасында қазақ халқының ұлттық өнерін әңгімелеу, ұлттық
                                аспап домбыра туралы түсінік беру, оқушы бойына музыкалық тәрбие
                                қалыптастыру, ұлттық өнерді сүйе білуге тәрбиелеу мақсатында домбыра
                                сабағы өткізіледі.
                              </div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Сабақ барысында оқушыларымызды домбыра аспабында ойнап үйренумен
                                «Шарықта, Дарын!» домбырашылар ансамблі «Ұлы дала -
                                таланттары»республикалық байқауында жүлделі ІІ орынды иеленді.
                                «Самға, Дарын!» домбыра ансамблі «Ұшқын» балалар мен жасөспірімдер
                                республикалық байқауында «Нәр ағашы» халық күйін орындап, жүлделі І
                                орынды қанжығасына байлады.
                              </div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                «Шарықта, Дарын!» домбыра ансамблі «ҰШҚЫН» балалар мен жасөспірімдер
                                республикалық байқауында «Әке толғауы» шығармасымен Бас жүлде иегері
                                атанды.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="mugs_4" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={mugs_4} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Робот әлемі</h2>
                          <p className="body-color"></p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Мектебімізде академиялық білім берумен қатар, оқушылардың жан-жақты
                                дамуы мен робототехника сабағына қызығушылығын арттыру мақсатында
                                робототехника сабағы негізгі сабақ кестесіне сай өткізіледі. Теория
                                мен тәжірибе қатар өткізілетін сабақ барысында білімгерлеріміз өз
                                қолымен детальдардан роботтар құрап, нәтижесімен бөліседі.
                              </div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Мектеп оқушыларынан құралған “Big Boss” , «7 Тұлпар», «6 Самұрық»
                                командалары «Haileybury Fisrt Champioship» - дың алғашқы жаттығу
                                жарысына , “Alem Fisrt Champioship” – тың алғашқы жаттығу жарысына
                                қатысты. Сонымен қатар, мектебімізде STEM – ғылым, технология,
                                жаратылыстану және математика пәндерін біріктіру арқылы зерттеушілік
                                дағдыларын дамыту мақсатында Т.Рысқұлов атындағы №9 орта мектептің
                                оқушыларына “STEM - Робототехника” робототехника сабағы өткізіледі.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="mugs_5" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={mugs_5} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Көркемсөз байқауы</h2>
                          <p className="body-color"></p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                «Өлеңмен жанды тербеймін» айдарымен өтетін көркемсөз байқауының
                                мақсаты - білімгерлер арасында ақын – жазушылардың шығармаларын,
                                мысалдарын насихаттау, болашақ ұрпақтың бойында туған елді, туған
                                жерді құрметтеу мен бағалауды дәріптеу. Әр тоқсан сайын өтетін
                                байқауға сыныптың барлық оқушылары қамтылады. 1 байқауға қатысқан
                                оқушы келесі байқауға қатыстырылмайды.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="mugs_6" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={mugs_6} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Арт</h2>
                          <p className="body-color"></p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Мектебімізде арт сабағы негізгі сабақ кестесімен өтеді. Арт сабағын
                                маман иесі – суретші өткізеді. Арт сабағында оқушылар кескіндеме,
                                графика, қолданбалы өнер шығармаларымен танысады. Бұл - әрбір сабақ
                                оқушының эстетикалық тәрбиесіне, шығармашылық қабілеттерінің
                                дамуына, дүниетанымына, жан   жақты дамуына ықпал етеді. Оқушылардың
                                жұмыстарынан көрме ұйымдастырылады.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="mugs_7" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={mugs_7} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Өзге елге саяхат</h2>
                          <p className="body-color"></p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Тәрбие жұмысының бір қыры – оқушыларды өз елінің патриоттарын
                                тәрбиелеумен қатар, өзге елдің тарихын, саясаты, тұлғалары мен төл
                                мәдениетін зерттеп, тоқсан сайын презентациялайды. Презентация
                                кезінде дәстүрлерін көрсетіп, ұлттық билерін көрсетеді.
                                Презентацияға бірнеше сынып оқушылары қатысады. Бір тоқсанда – бір
                                елді толығымен танып, біледі.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="mugs_8" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={mugs_8} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Шахмат турнирі</h2>
                          <p className="body-color"></p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Шахмат – бірегей білім беру құралы. Шахматтың көптеген елдердің
                                мектеп бағдарламасына енгізілуі кездейсоқ емес. Шахматта ойнау
                                математикалық қабілетті, логиканы, стратегиялық жоспарлау құру
                                дағдыларын, төзімділікті, жауынгерлік қасиеттерді қалыптастыруға
                                ықпал ететіні белгілі. Мектепте шахмат сабағы негізгі сабақ
                                кестесімен өткізіледі. Осыған орай, әр тоқсан сайын барлық мектеп
                                оқушылары қатысатын турнирлер өткізіп, жеңімпаздар анықталады.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MugsPage;
