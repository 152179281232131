import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import background from "../../resources/img/other/background.jpg";
import PostService from "../../services/PostService";
import { PhoneMask, DateMask } from "../../utils/InputMasks";

const ContactsPage = () => {
  const [reviewTypeId, setReviewTypeId] = useState(1);
  const [sourceReviewId, setSourceReviewId] = useState(1);
  const [reviewText, setReviewText] = useState("");

  const [fio, setFio] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [experience, setExperience] = useState("");
  const [linkToResume, setLinkToResume] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [salary, setSalary] = useState("");
  const [gender, setGender] = useState("");
  
  const [isSendedReview, setIsSendedReview] = useState(false);
  const [isSendedResume, setIsSendedResume] = useState(false);

  const { t } = useTranslation();

  const submitReview = (e) => {
    e.preventDefault();
    PostService.setReview({
      reviewTypeId,
      reviewText,
      sourceReviewId,
    });
    setReviewTypeId("");
    setSourceReviewId("");
    setReviewText("");
    setIsSendedReview(true);
  };

  const submitResume = (e) => {
    e.preventDefault();
    PostService.setResume({
      fio,
      phoneNumber,
      email,
      experience,
      linkToResume,
      dateOfBirth,
      salary,
      gender,
    });
    setFio("");
    setPhoneNumber("");
    setEmail("");
    setExperience("");
    setLinkToResume("");
    setDateOfBirth("");
    setSalary("");
    setGender("");
    setIsSendedResume(true);
  };

  useEffect(() => {
    PhoneMask('#phone')
    DateMask('#birthday')
  }, []);

  return (
    <div className="contacts">
      <div
        className="hero parallax is-cover is-relative is-default is-bold"
        data-background={background}
        data-color="#000"
        data-color-opacity="0.3"
      >
        <div id="main-hero" className="hero-body pt-20 pb-20">
          <div className="container has-text-centered">
            <div className="columns is-vcentered is-centered">
              <div className="column is-8 has-text-centered is-header-caption">
                <h1 className="title is-spaced main-title is-2">
                  <span className="color__prime">{t("other.title_1")}</span>
                  {t("other.title_1_1")}
                </h1>
                <h2 className="subtitle is-5 light-text">{t("about.description_1")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section map">
        <div className="container">
          <div className="contact-page-1">
            <div className="columns">
              <iframe
                title="map"
                frameBorder="no"
                style={{ border: "1px solid #a3a3a3", boxSizing: "border-box" }}
                width="100%"
                height="600"
                src="https://widgets.2gis.com/widget?type=firmsonmap&amp;options=%7B%22pos%22%3A%7B%22lat%22%3A43.31076808818023%2C%22lon%22%3A77.22706317901613%2C%22zoom%22%3A16%7D%2C%22opt%22%3A%7B%22city%22%3A%22almaty%22%7D%2C%22org%22%3A%2270000001045482486%22%7D"
              ></iframe>
            </div>
            <div className="columns has-text-centered pt-80 pb-80">
              <div className="column is-hidden-mobile"></div>
              <div className="column is-3">
                <div className="shadow-icon-box rounded">
                  <span>
                    <i className="fa fa-map fa-lg"></i>
                  </span>
                </div>
                <div className="shadow-title dark-text">{t("contacts.address")}</div>
                <div className="description shadow-description">{t("contacts.text_1")}</div>
              </div>
              <div className="column is-3">
                <div className="shadow-icon-box rounded">
                  <span>
                    <i className="fa fa-envelope fa-lg"></i>
                  </span>
                </div>
                <div className="shadow-title dark-text">Email</div>
                <div className="description shadow-description">darynischool@gmail.com</div>
              </div>
              <div className="column is-3">
                <div className="shadow-icon-box rounded">
                  <span>
                    <i className="fa fa-phone-square fa-lg"></i>
                  </span>
                </div>
                <div className="shadow-title dark-text">{t("contacts.phone")}</div>
                <div className="description shadow-description">
                  <a href="tel:+77750074866">+7 775 007 48 66</a>
                </div>
              </div>
              <div className="column is-hidden-mobile"></div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        id="ReviewsForm"
        className="section has-border-top is-medium is-relative footer-wavy section-feature-grey"
      >
        <div className="container">
          <div className="section-title-wrapper has-text-centered">
            <div className="bg-number">?</div>
            <h2 className="section-title-landing">{t("form.review_title")}</h2>
            <h4 className="">{t("form.review_description")}</h4>
          </div>

          <div className="content-wrapper">
            <form onSubmit={submitReview} className="contact-material" id="send-review-form">
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-6">
                      <select
                        style={{ width: "100%" }}
                        id="reviewType"
                        className="input is-medium"
                        required
                        value={reviewTypeId}
                        onChange={(e) => {
                          setReviewTypeId(e.target.value);
                          console.log(e.target.value);
                        }}
                      >
                        <option>{t("form.review_type")}</option>
                        <option value={1}>{t("form.review_type_1")}</option>
                        <option value={2}>{t("form.review_type_2")}</option>
                        <option value={3}>{t("form.review_type_3")}</option>
                      </select>
                    </div>
                    <div className="column is-6">
                      <select
                        style={{ width: "100%" }}
                        id="reviewSource"
                        className="input is-medium"
                        required
                        value={sourceReviewId}
                        onChange={(e) => {
                          setSourceReviewId(e.target.value);
                          console.log(e.target.value);
                        }}
                      >
                        <option>{t("form.review_source")}</option>
                        <option value={1}>{t("form.review_source_1")}</option>
                        <option value={2}>{t("form.review_source_2")}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-12">
                      <textarea
                        className="input is-medium"
                        style={{ width: "100%", height: "200px" }}
                        id="reviewText"
                        placeholder={t("form.review_text")}
                        required
                        value={reviewText}
                        onChange={(e) => {
                          setReviewText(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="has-text-centered">
                    {!isSendedReview ?
                      <button
                        type="submit"
                        className="button button-cta btn-align primary-btn btn-outlined is-bold rounded raised no-lh"
                      >
                        {t("form.review_submit")}
                      </button>
                      :
                      <button
                        type="submit"
                        className="button button-cta btn-align btn-outlined is-bold rounded no-lh"
                        disabled
                      >
                        ✓
                      </button>
                    }
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}

      <div
        id="ResumeForm"
        className="section has-border-top is-medium is-relative footer-wavy section-feature-grey"
      >
        <div className="container">
          <div className="section-title-wrapper has-text-centered">
            <div className="bg-number">?</div>
            <h2 className="section-title-landing">{t("form.resume_title")}</h2>
            <h4 className="">{t("form.resume_description")}</h4>
          </div>

          <div className="content-wrapper">
            <form onSubmit={submitResume} className="contact-material form" id="send-resume-form">
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-12">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="text"
                        name="fullName"
                        required
                        placeholder={t("form.resume_name")}
                        value={fio}
                        onChange={(e) => {
                          setFio(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-6">
                      <span className="shell">
                        <input
                          className="input is-medium masked"
                          style={{ width: "100%" }}
                          type="tel"
                          name="phone"
                          id="phone"
                          placeholder={t("form.phone")}
                          required
                          value={phoneNumber}
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                        />
                      </span>
                    </div>
                    <div className="column is-6">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="email"
                        name="email"
                        required
                        placeholder={t("form.resume_email")}
                        value={email}
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-6">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="number"
                        name="experience"
                        min="1"
                        required
                        placeholder={t("form.resume_experience")}
                        value={experience}
                        onChange={(e) => {
                          setExperience(e.target.value);
                        }}
                      />
                    </div>
                    <div className="column is-6">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="number"
                        name="salary"
                        min="1"
                        required
                        placeholder={t("form.resume_salary")}
                        value={salary}
                        onChange={(e) => {
                          setSalary(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-6">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="text"
                        name="birthday"
                        id="birthday"
                        required
                        placeholder={t("form.resume_birthday")}
                        value={dateOfBirth}
                        onChange={(e) => {
                          setDateOfBirth(e.target.value);
                        }}
                      />
                    </div>
                    <div className="column is-6">
                      <label style={{ fontSize: "12px", color: "white" }}>
                        {t("form.resume_gender")}
                      </label>
                      <div
                        style={{
                          paddingBottom: "30px",
                          color: "#cecece",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="genderRadio1"
                            value="1"
                            required
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                          />
                          <label className="form-check-label" htmlFor="genderRadio1">
                            &nbsp; {t("form.man")}
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="genderRadio2"
                            value="2"
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                          />
                          <label className="form-check-label" htmlFor="genderRadio2">
                            &nbsp; {t("form.woman")}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-12">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="text"
                        name="linkToResume"
                        required
                        placeholder={t("form.resume_link")}
                        value={linkToResume}
                        onChange={(e) => {
                          setLinkToResume(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="has-text-centered huge-pb">
                    {!isSendedResume ?
                      <button
                        type="submit"
                        className="button button-cta btn-align primary-btn btn-outlined is-bold rounded raised no-lh"
                      >
                        {t("form.resume_submit")}
                      </button>
                      :
                      <button
                        type="submit"
                        className="button button-cta btn-align btn-outlined is-bold rounded no-lh"
                        disabled
                      >
                        ✓
                      </button>
                    }
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsPage;
