import { Link, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  return (
    <nav className="breadcrumb styled">
      <ul>
        {pathnames.map((path, i) => {
          const link = `/${pathnames.slice(0, i + 1).join("/")}`;
          return (
            <li key={path + i}>
              <Link to={link} className="breadcrumb-item">
                {path}
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
