import background from "../../resources/img/other/background.jpg";

const VacanciesPage = () => {
  return (
    <div className="vacancies">
      <div
        className="hero parallax is-cover is-relative is-default is-bold"
        data-background={background}
        data-color="#000"
        data-color-opacity="0.3"
      >
        <div id="main-hero" className="hero-body pt-80 pb-80">
          <div className="container has-text-centered">
            <div className="columns is-vcentered is-centered">
              <div className="column is-8 has-text-centered is-header-caption">
                <h1 className="title is-spaced main-title is-2">
                  <br />
                  <span className="color__prime">Вакансии</span>
                </h1>
                <h2 className="subtitle is-5 light-text">Сапалы білім болашақ ұрпақ үшін.</h2>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="ResumeForm"
        className="section has-border-top is-medium is-relative footer-wavy section-feature-grey"
      >
        <div className="container">
          <div className="section-title-wrapper has-text-centered">
            <div className="bg-number">?</div>
            <h2 className="section-title-landing">Отправьте свое резюме</h2>
            <h4 className="">
              Мы добавим информацию о Вашем резюме в нашу базу. Это увеличит возможность
              трудоустройства в нашу школу.
            </h4>
          </div>

          <div className="content-wrapper">
            <form className="contact-material form" id="send-resume-form">
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-12">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="text"
                        name="fullName"
                        required
                        placeholder="Введите Ваше полное ФИО *"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-6">
                      <span className="shell">
                        <input
                          className="input is-medium masked"
                          style={{ width: "100%" }}
                          type="tel"
                          name="phone"
                          id="phone"
                          pattern="+\d\(\d{3}\) \d{3}\-\d{4}"
                          value="+7"
                          required
                          maxlength="16"
                          data-placeholder="+X(XXX) XXX-XXXX"
                        />
                      </span>
                    </div>
                    <div className="column is-6">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="email"
                        name="email"
                        required
                        placeholder="Ваш Email *"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-6">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="number"
                        name="experience"
                        min="1"
                        required
                        placeholder="Ваш стаж*"
                      />
                    </div>
                    <div className="column is-6">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="number"
                        name="salary"
                        min="1"
                        required
                        placeholder="Ожидаемый оклад *"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-6">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="text"
                        name="birthday"
                        id="birthday"
                        required
                        placeholder="Дата рождения дд.мм.гггг *"
                      />
                    </div>
                    <div className="column is-6">
                      <label style={{ fontSize: "12px", color: "white" }}>Ваш пол *</label>
                      <div
                        style={{
                          paddingBottom: "30px",
                          color: "#cecece",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="genderRadio1"
                            value="1"
                            required
                          />
                          <label className="form-check-label" for="genderRadio1">
                            Мужской
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="gender"
                            id="genderRadio2"
                            value="2"
                          />
                          <label className="form-check-label" for="genderRadio2">
                            Женский
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="columns is-vcentered">
                    <div className="column is-12">
                      <input
                        className="input is-medium"
                        style={{ width: "100%" }}
                        type="text"
                        name="linkToResume"
                        required
                        placeholder="Ссылка на резюме*"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column is-6 is-offset-3">
                  <div className="has-text-centered huge-pb">
                    <button
                      type="submit"
                      className="button button-cta btn-align btn-outlined is-bold rounded no-lh"
                      id="SubmitResumeFormButton"
                    >
                      Отправить резюме
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacanciesPage;
