import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import AppHeader from "../components/layouts/appHeader/AppHeader";
import AppFooter from "../components/layouts/appFooter/AppFooter";
import AppRouter from "../components/appRouter/AppRouter";
import ScrollToTop from "../utils/ScrollToTop";

import "../utils/i18n";

import "../styles/default.scss";
import "../styles/style.scss";
import "../styles/mobile.scss";

function App() {
  return (
    <Router>
      <div className="App">
        <div className="antifooter">
          <AppHeader />
          <main>
            <AppRouter />
          </main>
        </div>
        <AppFooter />
        <div id="backtotop_" className="visible backtotop">
          <a href="#"></a>
        </div>
      </div>
    </Router>
  );
}

export default App;
