import Breadcrumbs from "../../../components/layouts/breadcrumbs/Breadcrumbs";

import system_1 from "../../../resources/img/pages/system/system_1.jpg";
import system_2 from "../../../resources/img/pages/system/system_2.jpg";
import system_3 from "../../../resources/img/pages/system/system_3.jpg";
import system_4 from "../../../resources/img/pages/system/system_4.jpg";
import system_5 from "../../../resources/img/pages/system/system_5.jpg";
import system_6 from "../../../resources/img/pages/system/system_6.jpg";

const SystemPage = () => {
  return (
    <div className="system about__child">
      <div className="section">
        <div className="container">
          <Breadcrumbs />
          <div className="section-title-wrapper has-text-centered mb-40">
            <div className="bg-number"></div>
            <h2 className="section-title-landing">БІЛІМ БЕРУ ЖҮЙЕСІ</h2>
          </div>

          <div className="content-wrapper tabbed-features">
            <div className="columns is-vcentered">
              <div className="column">
                <div className="navigation-tabs outlined-pills animated-tabs">
                  <div className="tabs is-centered">
                    <ul>
                      <li className="tab-link is-active" data-tab="system_1">
                        <a>Мұғалімдердің кәсіби дамуы</a>
                      </li>
                      <li className="tab-link" data-tab="system_2">
                        <a>Тереңдетілген пәндер</a>
                      </li>
                      <li className="tab-link" data-tab="system_3">
                        <a>Пәндік олимпиада</a>
                      </li>
                      <li className="tab-link" data-tab="system_4">
                        <a>Жеке даму траекториясы</a>
                      </li>
                      <li className="tab-link" data-tab="system_5">
                        <a>Кітап оқу мәдениеті</a>
                      </li>
                      <li className="tab-link" data-tab="system_6">
                        <a>Ынталандыру бағдарламасы</a>
                      </li>
                    </ul>
                  </div>
                  <div id="system_1" className="navtab-content is-active">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={system_1} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Мұғалімдердің кәсіби дамуы</h2>
                          <p className="body-color"></p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Оқушылардың пәндік білімін терең меңгеріп, концептуалды ой
                                қалыптастыру мақсатында мектебіміздің стратегиялық жоспарына сай
                                мұғалімдер әрдайым коллаборативті жұмыстар жасайды. Сабақтарды
                                жоспарлаған сәттен бастап оқушының бойында креативті, сыни,
                                коммуникативті, коллаборативті (бірлескен жұмыс) дағдыларын дамытуға
                                бағытталған әдіс-тәсілдер қолданылады. Сонымен қатар сабақ беру
                                жүйесі Марцано таксономиясы
                                «білу-түсіну-қолдану-талдау-синтез-бағалау» арқылы құрастырылған. IB
                                PYP бағдарламасы аясында зерттеу дағдыларына да қатты көңіл
                                бөлінеді.
                              </div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Ұстаздарға коллаборативті жоспарлауға, кәсіби дамуына арналған
                                семинарларға, кітаптар талдауға әр жұма сайын 16.00-18.00 арасында
                                арнайы уақыт қарастырылған.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="system_2" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={system_2} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Тереңдетілген пәндер</h2>
                          <p className="body-color">
                            Тереңдетілген математика және ағылшын тіліндегі Сингапур математикасы,
                            ағылшын тілі пәні, арнайы зерттеушілік бағытында қазақ әдебиеті мен
                            қазақ тілі пәндері өтеді. Сағат саны мемлекеттік стандартқа қарағанда
                            аптасына 2-5 сағатқа артық жүргізіледі. Мысалы: математика пәні Сингапур
                            математикасы, Петерсон авторлық оқулықтарымен қатар артық сағаттармен
                            тереңдетіліп қосымша өтеді.
                          </p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="system_3" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={system_3} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Пәндік олимпиада</h2>
                          <p className="body-color">
                            Мектебіміз математика-логикалық Mega Brain және Wise Owl ағылшын пәнінен
                            тоқсанына бір рет аудандық олимпиадалар ұйымдастырады. Осы олимпиаданың
                            мақсаты - математика және ағылшын пәндерінен интеллектуалды қабілеттері
                            арқылы адал бәсекелестік пен табандылықты дамыту. Бір жылдың ішінде
                            мектептің барлық оқушылары осы екі олимпиадаға қатысуы талап етіледі.
                            Мектептен тыс – аудандық, облыстық, Ақбота, Кенгуру, FEMO international
                            Olympiad, Tamos winter games, Kids A-Z reading, First robotics Olympiad
                            олимпиадаларына қатысады.
                          </p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="system_4" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={system_4} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Жеке даму траекториясы</h2>
                          <p className="body-color"></p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Оқушының жеке даму траекториясындағы білім сапасы арнайы рейтинг
                                бағалау жұмыстары, қорытынды емтихан нәтижелері, әкімшілік тексеру
                                жұмыстары арқылы өлшенеді. Мектеп әкімшілігі оқушылардың білім
                                сапасындағы өзгерістерді талдау жұмыстарын 2-3 апта сайын жүйелі
                                түрде жүргізеді. Бұл талдаудың қорытындысы ретінде оқушылармен жеке
                                және топтық қосымша меңгеру сағаттары ұйымдастырылады. Оқушылардың
                                барлығы сәуір айында жылдық қорытынды емтихан тапсырады.
                              </div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Ал жеке қабілетті DISC методикасы арқылы жыл бойы оқушының бойындағы
                                қабілеттерді бақылап, дамытуға жұмыс жасалынады. Осы ретте оқушылар
                                бірнеше бағытта инновациялық жобалар жасауға топтастырылып, арнайы
                                алаңдарда қорғайды. Қызығушылығын арттыратын қосымша әдебиеттерді де
                                оқиды.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="system_5" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={system_5} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Кітап оқу мәдениеті</h2>
                          <p className="body-color">
                            Кітап оқу мәдениеті - білім алудың таптырмас көзі. Осы бағытта әр
                            сыныпта кітапхана сағаты аптасына 1-2 реттен қарастырылған. Барлық
                            оқушыға кітаптар саны жеткілікті және үш тілдегі кітаптарды таңдай
                            алады. Бұл сабақты кітапханашы өткізеді. Кітаптарды бірге оқып,
                            талдап,ашық сабақтар өткізеді. Бір оқу жылында кем дегенде 5 көркем
                            әдебиетті оқиды.
                          </p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="system_6" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={system_6} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Ынталандыру бағдарламасы</h2>
                          <p className="body-color">
                            Біздің мектепте оқушыларымыз құндылықтарды &nbsp;
                            <span className="text-bold">
                              (отансүйгіштік, намыс, жауапкершілік, жан-жақтылық, бірлік, достық,
                              табандылық)
                            </span>
                            &nbsp; және қасиеттерді &nbsp;
                            <span className="text-bold">
                              (ізденімпаз, ойшыл, білімқұмар, коммуникативті, ұстанымды,кең
                              ойлайтын, қамқоршыл, тәуекелшіл, үйлесімді дамыған, рефлексивті)
                            </span>
                            &nbsp; дамытатын істер, қадамдар жасау арқылы жұлдыздар жинайды. 500
                            жұлдыз жинаған сыныптар тоқсанына 1-2 рет театр қойылымдарына,
                            мұражайларға жолдама алады
                          </p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemPage;
