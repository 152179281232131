import { Link } from "react-router-dom";
import background from "../../resources/img/other/background.jpg";
import { album_1, album_bg } from "./images";
import { useTranslation } from "react-i18next";

const GalleryPage = () => {
  const { t } = useTranslation();
  return (
    <div className="gallery">
      <div
        className="hero parallax is-cover is-relative is-default is-bold"
        data-background={background}
        data-color="#000"
        data-color-opacity="0.3"
      >
        <div id="main-hero" className="hero-body pt-20 pb-20">
          <div className="container has-text-centered">
            <div className="columns is-vcentered is-centered">
              <div className="column is-8 has-text-centered is-header-caption">
                <h1 className="title is-spaced main-title is-2">
                  <span className="color__prime">{t("gallery.title_1")}</span>
                </h1>
                <h2 className="subtitle is-5 light-text">{t("gallery.description_1")}</h2>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section is-medium section-feature-grey">
        <div className="container">
          <div className="title-wrap has-text-centered">
            <h2 className="title is-spaced is-2">{t("gallery.title_2")}</h2>
            <h3></h3>
            <img src="assets/img/graphics/legacy/divider.svg" className="special-divider" alt="" />
          </div>
          <div className="content-wrapper">
            <div className="job-categories">
              <div className="job-categories-inner">
                <Link to="/gallery/album-1" className="job-category tilt-card rounded">
                  <div className="job-category-overlay"></div>
                  <div className="job-category-image">
                    <img src={album_1.gallery_13} alt="" />
                  </div>
                  <div className="job-category-heading">
                    <span className="small">01.09.2023</span>
                    <h2>{t("gallery.album_title_1")}</h2>
                  </div>
                </Link>
                <Link to="/gallery/album-2" className="job-category tilt-card rounded">
                  <div className="job-category-overlay"></div>
                  <div className="job-category-image">
                    <img src={album_bg.album_2_21} alt="" />
                  </div>
                  <div className="job-category-heading">
                    <span className="small">09.12.2023</span>
                    <h2>{t("gallery.album_title_2")}</h2>
                  </div>
                </Link>
                <Link to="/gallery/album-3" className="job-category tilt-card rounded">
                  <div className="job-category-overlay"></div>
                  <div className="job-category-image">
                    <img src={album_bg.album_3_bg} alt="" />
                  </div>
                  <div className="job-category-heading">
                    <span className="small">20.05.2023</span>
                    <h2>{t("gallery.album_title_3")}</h2>
                  </div>
                </Link>
                <Link to="/gallery/album-4" className="job-category tilt-card rounded">
                  <div className="job-category-overlay"></div>
                  <div className="job-category-image">
                    <img src={album_bg.album_4_bg} alt="" />
                  </div>
                  <div className="job-category-heading">
                    <span className="small">30.05.2023</span>
                    <h2>{t("gallery.album_title_4")}</h2>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GalleryPage;
