import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// import useApiService from "../../../services/ApiService";

import logo from "../../../resources/img/logo/logo_kz.png";
import instagram from "../../../resources/img/icons/social/instagram.svg";
import youtube from "../../../resources/img/icons/social/youtube.svg";
import whatsapp from "../../../resources/img/icons/social/whatsapp.svg";

const AppHeader = () => {
  // const { getHeader } = useApiService();
  // const [menu, setMenu] = useState({});
  // const [lang, setLang] = useState("");
  const { t, i18n } = useTranslation();
  const [mobileMenu, setMobileMenu] = useState(true);

  useEffect(() => {
    // getHeader().then(setMenu);     // загрузка данных с getCockpit
    // i18n.changeLanguage(localStorage.getItem("language"));     // принудительная смена языка
  }, []);

  const changeLang = (lang) => {
    i18n.changeLanguage(lang);
    // localStorage.setItem("language", lang);
  };

  const toggleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  return (
    <header>
      <nav className="navbar navbar-wrapper navbar-default navbar-fade is-transparent">
        <div className="container">
          <div className="navbar-brand">
            <Link className="navbar-item" to="/">
              <img className="switcher-logo" src={logo} alt="" />
            </Link>

            <div
              className={mobileMenu ? "custom-burger" : "custom-burger is-active"}
              data-target=""
              onClick={toggleMobileMenu}
            >
              <div id="" className="responsive-btn" href="#">
                {/* <a id="" className="responsive-btn" href="javascript:void(0);"> */}
                <span className="menu-toggle">
                  <span className={mobileMenu ? "icon-box-toggle" : "icon-box-toggle is-active"}>
                    <span className="rotate">
                      <i className="icon-line-top"></i>
                      <i className="icon-line-center"></i>
                      <i className="icon-line-bottom"></i>
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div className={mobileMenu ? "navbar-menu" : "navbar-menu is-active"}>
            <div className="navbar-start" onClick={toggleMobileMenu}>
              <Link className="navbar-item is-slide" to="/">
                {t("header.menu_1")}
              </Link>
              <Link className="navbar-item is-slide" to="/about">
                {t("header.menu_2")}
              </Link>
              <Link className="navbar-item is-slide" to="/politics">
                {t("header.menu_3")}
              </Link>
              <Link className="navbar-item is-slide" to="/gallery">
                {t("header.menu_4")}
              </Link>
              <Link className="navbar-item is-slide" to="/teachers">
                {t("header.menu_5")}
              </Link>
              <Link className="navbar-item is-slide" to="/FAQ">
                {t("header.menu_6")}
              </Link>
              {/* <Link className="navbar-item is-slide" to="/books">
                {menu[`menu_5${lang}`]}
              </Link> */}
              {/* <Link className="navbar-item is-slide" to="/vacancies">
                {menu[`menu_6${lang}`]}
              </Link> */}
              <Link className="navbar-item is-slide" to="/contacts">
                {t("header.menu_7")}
              </Link>
            </div>
            <div className="navbar-end navbar__social">
              <a
                className="navbar-item"
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/darynimektebi/"
              >
                <img src={instagram} alt="instagram" />
              </a>
              <a
                className="navbar-item"
                target="_blank"
                rel="noreferrer"
                href="https://www.youtube.com/@daryn2019"
              >
                <img src={youtube} alt="youtube" />
              </a>
              <a
                className="navbar-item"
                target="_blank"
                rel="noreferrer"
                href="https://wa.me/+77054906531"
              >
                <img src={whatsapp} alt="whatsapp" />
              </a>
            </div>
            {/* <div className="navbar-end navbar__language">
              <button
                className="navbar-item is-slide"
                disabled={i18n.resolvedLanguage === "kz"}
                onClick={() => changeLang("kz")}
              >
                KZ
              </button>
              <button
                className="navbar-item is-slide"
                disabled={i18n.resolvedLanguage === "en"}
                onClick={() => changeLang("en")}
              >
                ENG
              </button>
            </div> */}
          </div>
        </div>
      </nav>
    </header>
  );
};

export default AppHeader;
