import $ from 'jquery';
import 'jquery-mask-plugin/dist/jquery.mask.min'; 

const PhoneMask = (id) => {
    $(id).mask('+0(000)000-0000', {})
};

const DateMask = (id) => {
    $(id).mask('00.00.0000');
}

export {PhoneMask, DateMask};