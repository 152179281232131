import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import PostService from "../../services/PostService";
import {PhoneMask} from "../../utils/InputMasks";

import title_img from "../../resources/img/other/main_banner.jpg";
import teacher_4 from "../../resources/img/pages/teachers/woman-ipad.jpg";
import doc_1 from "../../resources/docs/Юнит_орта_идея.pdf";
import doc_2 from "../../resources/docs/Юнит_Біз_кімбіз.pdf";
import doc_3 from "../../resources/docs/Юнит_Біз_өзімізді_қалай_білдіреміз.pdf";
import doc_4 from "../../resources/docs/Юнит_Біз_қайда_орналасқанбыз.pdf";
import doc_5 from "../../resources/docs/Юнит_Әлем_қалай_жұмыс_істейді.pdf";
import doc_6 from "../../resources/docs/Юнит_Өз_өзімізді_қалай_ұйымдастырамыз.pdf";
import doc_7 from "../../resources/docs/Юнит_Планета_біздің_ортақ_үйіміз.pdf";
import instagram from "../../resources/img/icons/social/instagram.svg";

const MainPage = () => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [grade, setGrade] = useState("");
  const [isSended, setIsSended] = useState(false);

  const SubmitForm = (e) => {
    e.preventDefault();
    PostService.setPost({
      name,
      phoneNumber,
      grade,
    });
    setName("");
    setPhoneNumber("");
    setGrade("");
    setIsSended(true);
  };

  useEffect(() => {
    PhoneMask('#phone')
  }, []);

  return (
    <div className="home">
      <div className="hero product-hero is-app-grey is-fullheight is-default is-bold">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="columns is-vcentered">
              <div className="column is-5 signup-column has-text-left">
                <h1 className="title text-bold is-2">{t("main.title_1")}</h1>
                <h2 className="subtitle is-5 no-margin-bottom body-color">
                  {t("main.description_1")}
                </h2>
                <div className="pt-20 pb-20">
                  <a href="#mainForm" className="button button-cta primary-btn rounded raised">
                    {t("other.submit")}
                  </a>
                </div>
              </div>
              <div className="column is-7">
                <figure className="image">
                  <img src={title_img} alt="" />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="services"
        className="section is-medium parallax is-cover is-relative"
        data-background={teacher_4}
        data-color="#000"
        data-color-opacity="0.7"
      >
        <div className="container">
          <div className="section-title-wrapper has-text-centered">
            <h2 className="section-title-landing light-text">{t("main.title_2")}</h2>
          </div>

          <div className="content-wrapper">
            <div className="columns is-vcentered is-multiline has-text-centered">
              <div className="column is-3">
                <div className="wavy-icon-box">
                  <div className="is-icon-reveal">
                    <i className="im im-icon-Hospital"></i>
                  </div>
                  <div className="box-title">{t("main.box_title_1")}</div>
                  <p className="box-content is-tablet-padded light-text">{t("main.box_text_1")}</p>
                </div>
              </div>
              <div className="column is-3">
                <div className="wavy-icon-box">
                  <div className="is-icon-reveal">
                    <i className="im im-icon-Open-Book"></i>
                  </div>
                  <div className="box-title">Тереңдетілген білім</div>
                  <p className="box-content is-tablet-padded light-text">
                    Математика, ағылшын тілі, арнайы зерттеушілік бағытында қазақ әдебиеті мен қазақ
                    тілі.
                  </p>
                </div>
              </div>
              <div className="column is-3">
                <div className="wavy-icon-box">
                  <div className="is-icon-reveal">
                    <i className="im im-icon-Dj"></i>
                  </div>
                  <div className="box-title">{t("main.box_title_3")}</div>
                  <p className="box-content is-tablet-padded light-text">{t("main.box_text_3")}</p>
                </div>
              </div>
              <div className="column is-3">
                <div className="wavy-icon-box">
                  <div className="is-icon-reveal">
                    <i className="im im-icon-Guitar"></i>
                  </div>
                  <div className="box-title">Дарын жұлдыздары</div>
                  <p className="box-content is-tablet-padded light-text">
                    Жылдық қорытынды концерт, театр және мұражайларға бару
                  </p>
                </div>
              </div>
              <div className="column is-3 is-offset-1">
                <div className="wavy-icon-box">
                  <div className="is-icon-reveal">
                    <i className="im im-icon-Global-Position"></i>
                  </div>
                  <div className="box-title">Құндылықтар мен қасиеттер</div>
                  <p className="box-content is-tablet-padded light-text">
                    Әлем дамуына үлес қосу үшін мен қандай болуым керек?
                  </p>
                </div>
              </div>
              <div className="column is-3">
                <div className="wavy-icon-box">
                  <div className="is-icon-reveal">
                    <i className="im im-icon-Sand-watch2"></i>
                  </div>
                  <div className="box-title">{t("main.box_title_4")}</div>
                  <p className="box-content is-tablet-padded light-text">{t("main.box_text_4")}</p>
                </div>
              </div>
              <div className="column is-3">
                <div className="wavy-icon-box">
                  <div className="is-icon-reveal">
                    <i className="im im-icon-Cap-Smiley"></i>
                  </div>
                  <div className="box-title">{t("main.box_title_2")}</div>
                  <p className="box-content is-tablet-padded light-text">{t("main.box_text_2")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="features" className="section is-medium ib_pyp">
        <div className="container has-text-centered">
          <div className="section-title-wrapper has-text-centered">
            <h2 className="section-title-landing">{t("main.title_3")}</h2>
            <div className="centered-title">
              <div className="title-divider"></div>
              <div className="subheading">{t("main.subtitle_1")}</div>
              <div className="subheading">{t("main.subtitle_2")}</div>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="columns is-vcentered has-text-centered">
              <div className="column is-hidden-mobile"></div>
              <div className="column is-3">
                <div className="square-icon-box is-landing primary">
                  <div className="icon-box-wrapper">
                    <a href={doc_2} target="_blank" rel="noreferrer" className="icon-box">
                      <i className="material-icons">work</i>
                    </a>
                  </div>
                  <div className="box-title">{t("main.box_title_5")}</div>
                  <div className="box-text is-tablet-padded body-color"></div>
                </div>
              </div>
              <div className="column is-3">
                <div className="square-icon-box is-landing primary">
                  <div className="icon-box-wrapper">
                    <a href={doc_3} target="_blank" rel="noreferrer" className="icon-box">
                      <i className="material-icons">assignment_turned_in</i>
                    </a>
                  </div>
                  <div className="box-title">{t("main.box_title_6")}</div>
                  <div className="box-text is-tablet-padded body-color"></div>
                </div>
              </div>
              <div className="column is-3">
                <div className="square-icon-box is-landing primary">
                  <div className="icon-box-wrapper">
                    <a href={doc_4} target="_blank" rel="noreferrer" className="icon-box">
                      <i className="material-icons">chat_bubble</i>
                    </a>
                  </div>
                  <div className="box-title">{t("main.box_title_7")}</div>
                  <div className="box-text is-tablet-padded body-color"></div>
                </div>
              </div>
              <div className="column is-hidden-mobile"></div>
            </div>
            <div className="columns is-vcentered has-text-centered">
              <div className="column is-hidden-mobile"></div>
              <div className="column is-3">
                <div className="square-icon-box is-landing primary">
                  <div className="icon-box-wrapper">
                    <a href={doc_5} target="_blank" rel="noreferrer" className="icon-box">
                      <i className="material-icons">dashboard</i>
                    </a>
                  </div>
                  <div className="box-title">{t("main.box_title_8")}</div>
                  <div className="box-text is-tablet-padded body-color"></div>
                </div>
              </div>
              <div className="column is-3">
                <div className="square-icon-box is-landing primary">
                  <div className="icon-box-wrapper">
                    <a href={doc_6} target="_blank" rel="noreferrer" className="icon-box">
                      <i className="material-icons">home</i>
                    </a>
                  </div>
                  <div className="box-title">{t("main.box_title_9")}</div>
                  <div className="box-text is-tablet-padded body-color"></div>
                </div>
              </div>
              <div className="column is-3">
                <div className="square-icon-box is-landing primary">
                  <div className="icon-box-wrapper">
                    <a href={doc_7} target="_blank" rel="noreferrer" className="icon-box">
                      <i className="material-icons">language</i>
                    </a>
                  </div>
                  <div className="box-title">{t("main.box_title_10")}</div>
                  <div className="box-text is-tablet-padded body-color"></div>
                </div>
              </div>
              <div className="column is-hidden-mobile"></div>
            </div>
            <div className="pb-20 pt-20 is-title-reveal">
              <a
                href={doc_1}
                download
                className="button button-cta btn-align primary-btn rounded raised"
              >
                {t("other.download")}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="section is-cover is-medium is-relative">
        <div className="container">
          <div className="columns is-vcentered">
            <div className="column is-5 is-offset-1">
              <div className="side-block">
                <div className="background-wrapper">
                  <div
                    id="video-embed"
                    className="video-wrapper"
                    data-url="https://www.youtube.com/watch?v=MIE7y1yJlEs"
                  >
                    <div className="video-overlay"></div>
                    <div className="playbutton">
                      <div className="icon-play">
                        <i className="im im-icon-Play-Music"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="column is-4 is-offset-1">
              <div className="content">
                <h2 className="title is-2 is-landing no-margin-bottom">
                  {t("main.youtube_title")}
                </h2>
                <p className="no-margin-bottom pt-10 mt-10">{t("main.youtube_description")}</p>
                <div className="pt-10 mt-10 media__links">
                  <a
                    href="https://www.youtube.com/@daryn2019"
                    target="_blank"
                    rel="noreferrer"
                    className="button button-cta primary-btn btn-outlined rounded is-bold"
                  >
                    {t("main.youtube_link")}
                  </a>
                  <a
                    className=""
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/darynimektebi/"
                  >
                    <img src={instagram} alt="instagram" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section is-medium is-relative section-feature-grey">
        <div className="container">
          <div className="section-title-wrapper has-text-centered">
            {/* <!-- <div className="bg-number">6</div> --> */}
            <h2 className="section-title-landing">{t("main.title_5")}</h2>
            <h4>{t("main.subtitle_3")}</h4>
          </div>
          <div className="content-wrapper pb-40">
              <form id="mainForm" onSubmit={SubmitForm}>
                <div className="columns">
                  <div className="column is-8 is-offset-2">
                    <div className="columns is-vcentered">
                      <div className="column is-3">
                        <div className="control-material is-primary">
                          <input
                            className="material-input"
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            type="text"
                            name="name"
                            required
                          />
                          <span className="material-highlight"></span>
                          <span className="bar"></span>
                          <label>{t("form.name")}</label>
                        </div>
                      </div>
                      <div className="column is-3">
                        <div className="control-material is-primary">
                          <input
                            className="material-input"
                            onChange={(e) => setGrade(e.target.value)}
                            value={grade}
                            type="number"
                            name="grade"
                            required
                            min="0"
                            max="12"
                          />
                          <span className="material-highlight"></span>
                          <span className="bar"></span>
                          <label>{t("form.class")}</label>
                        </div>
                      </div>
                      <div className="column is-3">
                        <div className="control-material is-primary">
                          <input
                            className="material-input masked"
                            type="tel"
                            name="phone"
                            id="phone"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            required
                          />
                          <span className="material-highlight"></span>
                          <span className="bar"></span>
                          <label>{t("form.phone")}</label>
                        </div>
                      </div>
                      <div className="column is-3">
                      {!isSended ? 
                        <button
                          type="submit"
                          className="button button-cta btn-align primary-btn btn-outlined is-bold rounded raised no-lh"
                        >
                          {t("form.submit")}
                        </button>
                        : 
                        <button
                          type="submit"
                          className="button button-cta btn-align primary-btn btn-outlined is-bold rounded raised no-lh"
                          disabled
                        >
                          ✓
                        </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainPage;
