import gallery_1 from "../../resources/img/pages/gallery/album_1/gallery_1.jpg";
import gallery_2 from "../../resources/img/pages/gallery/album_1/gallery_2.jpg";
import gallery_3 from "../../resources/img/pages/gallery/album_1/gallery_3.jpg";
import gallery_4 from "../../resources/img/pages/gallery/album_1/gallery_4.jpg";
import gallery_5 from "../../resources/img/pages/gallery/album_1/gallery_5.jpg";
import gallery_6 from "../../resources/img/pages/gallery/album_1/gallery_6.jpg";
import gallery_7 from "../../resources/img/pages/gallery/album_1/gallery_7.jpg";
import gallery_8 from "../../resources/img/pages/gallery/album_1/gallery_8.jpg";
import gallery_9 from "../../resources/img/pages/gallery/album_1/gallery_9.jpg";
import gallery_10 from "../../resources/img/pages/gallery/album_1/gallery_10.jpg";
import gallery_11 from "../../resources/img/pages/gallery/album_1/gallery_11.jpg";
import gallery_12 from "../../resources/img/pages/gallery/album_1/gallery_12.jpg";
import gallery_13 from "../../resources/img/pages/gallery/album_1/gallery_13.jpg";
import gallery_14 from "../../resources/img/pages/gallery/album_1/gallery_14.jpg";
import gallery_15 from "../../resources/img/pages/gallery/album_1/gallery_15.jpg";
import gallery_16 from "../../resources/img/pages/gallery/album_1/gallery_16.jpg";
import gallery_17 from "../../resources/img/pages/gallery/album_1/gallery_17.jpg";
import gallery_18 from "../../resources/img/pages/gallery/album_1/gallery_18.jpg";

import album_2_21 from "../../resources/img/pages/gallery/album_2/album_2.21.jpg";
import album_3_bg from "../../resources/img/pages/gallery/album_3/2023_05_20_10_27_IMG_5545.jpg";
import album_4_bg from "../../resources/img/pages/gallery/album_4/2023_05_30_08_44_IMG_5621.jpg";

const album_1 = {
  gallery_1,
  gallery_2,
  gallery_3,
  gallery_4,
  gallery_5,
  gallery_6,
  gallery_7,
  gallery_8,
  gallery_9,
  gallery_10,
  gallery_11,
  gallery_12,
  gallery_13,
  gallery_14,
  gallery_15,
  gallery_16,
  gallery_17,
  gallery_18,
};
const album_bg = {
  album_2_21,
  album_3_bg,
  album_4_bg,
};

export { album_1, album_bg };
