import Breadcrumbs from "../../../components/layouts/breadcrumbs/Breadcrumbs";
import { album_1 } from "../images";

const Album_1 = () => {
  return (
    <div className="component__wrapper">
      <div className="section gallery-block">
        <div className="container">
          <Breadcrumbs />
          <div className="gallery-block__wrap">
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_13}>
              <img src={album_1.gallery_13} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_2}>
              <img src={album_1.gallery_2} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_8}>
              <img src={album_1.gallery_8} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_5}>
              <img src={album_1.gallery_5} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_4}>
              <img src={album_1.gallery_4} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_18}>
              <img src={album_1.gallery_18} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_6}>
              <img src={album_1.gallery_6} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_10}>
              <img src={album_1.gallery_10} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_12}>
              <img src={album_1.gallery_12} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_14}>
              <img src={album_1.gallery_14} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_9}>
              <img src={album_1.gallery_9} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_7}>
              <img src={album_1.gallery_7} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_15}>
              <img src={album_1.gallery_15} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_17}>
              <img src={album_1.gallery_17} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_1}>
              <img src={album_1.gallery_1} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_11}>
              <img src={album_1.gallery_11} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_16}>
              <img src={album_1.gallery_16} alt="" />
            </a>
            <a className="gallery-block__item" data-fancybox="gallery" data-src={album_1.gallery_3}>
              <img src={album_1.gallery_3} alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Album_1;
