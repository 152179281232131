import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import background from "../../resources/img/other/background.jpg";
import about_0 from "../../resources/img/pages/about/about_0.jpg";
import about_1 from "../../resources/img/pages/about/about_1.jpg";
import about_2 from "../../resources/img/pages/about/about_2.jpg";
import about_3 from "../../resources/img/pages/about/about_3.jpg";
import about_4 from "../../resources/img/pages/about/about_4.jpg";
import about_5 from "../../resources/img/pages/about/about_5.jpg";
import about_6 from "../../resources/img/pages/about/about_6.jpg";
import about_7 from "../../resources/img/pages/about/about_7.jpg";
import about_8 from "../../resources/img/pages/about/about_8.jpg";
import about_9 from "../../resources/img/pages/about/about_9.jpg";
import about_10 from "../../resources/img/pages/about/about_10.jpg";

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <div className="about">
      <div
        className="hero parallax is-cover is-relative is-default is-bold"
        data-background={background}
        data-color="#000"
        data-color-opacity="0.3"
      >
        <div id="main-hero" className="hero-body pt-20 pb-20">
          <div className="container has-text-centered">
            <div className="columns is-vcentered is-centered">
              <div className="column is-8 has-text-centered is-header-caption">
                <h1 className="title is-spaced main-title is-2">
                  <span className="color__prime">{t("other.title_1")}</span>
                  {t("other.title_1_1")}
                </h1>
                <h2 className="subtitle is-5 light-text">{t("about.description_1")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section is-medium">
        <div className="container">
          <div className="section-title-wrapper has-text-centered">
            <h2 className="section-title-landing">{t("about.title_2")}</h2>
            <div className="centered-title">
              <div className="title-divider"></div>
            </div>
          </div>

          <div className="content-wrapper">
            <div className="columns is-vcentered pb-40 pt-40">
              <div className="column is-4">
                <div className="title quick-feature text-bold">{t("about.title_3")}</div>
                <div className="title-divider is-small"></div>
                <span className="body-text">{t("about.text_1")}</span>
              </div>
              <div className="column mission__image is-4">
                <img src={about_0} alt="" />
              </div>
              <div className="column is-4 is-hidden-mobile">
                <div className="title is-5">
                  Осы үлкен миссияға қол жеткізу үшін, біліммен қатар әрбір жеке тұлғаның бойында
                  адами құндылықтарды дамытамыз. Олар:
                </div>
                <div className="body-text">- Отансүйгіштік</div>
                <div className="body-text">- Намыс</div>
                <div className="body-text">- Жауапкершілік</div>
                <div className="body-text">- Жан-жақтылық</div>
                <div className="body-text">- Бірлік</div>
                <div className="body-text">- Достық</div>
                <div className="body-text">- Табандылық</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-feature-grey jobalar">
        <div className="container">
          <div className="centered-title">
            <h2>{t("about.title_4")}</h2>
            <div className="title-divider"></div>
            <div className="subheading">{t("about.subtitle_4")}</div>
          </div>
          <div className="content-wrapper">
            <div className="columns">
              <div className="column">
                <Link
                  to="/about/system"
                  className="feature-card card-md is-startup light-bordered hover-inset has-text-centered is-card-reveal"
                >
                  <div className="card-icon">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Open-Book"></i>
                    </div>
                  </div>
                  <div className="card-title">
                    <h4>{t("about.box_title_1")}</h4>
                  </div>
                  <div className="card-feature-description">
                    <span className=""></span>
                  </div>
                  <div className="button btn-align btn-more is-link color-primary mt-10 mb-10">
                    {t("other.more")} <i className="sl sl-icon-arrow-right"></i>
                  </div>
                </Link>
              </div>
              <div className="column">
                <Link
                  to="/about/mugs"
                  className="feature-card card-md is-startup light-bordered hover-inset has-text-centered is-card-reveal"
                >
                  <div className="card-icon">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Knight"></i>
                    </div>
                  </div>
                  <div>
                    <div className="card-title">
                      <h4>{t("about.box_title_2")}</h4>
                    </div>
                    <div className="card-feature-description">
                      <span className="">{t("about.box_subtitle_2")}</span>
                    </div>
                  </div>
                  <div className="button btn-align btn-more is-link color-primary mt-10 mb-10">
                    {t("other.more")} <i className="sl sl-icon-arrow-right"></i>
                  </div>
                </Link>
              </div>
              <div className="column">
                <Link
                  to="/about/social"
                  className="feature-card card-md is-startup light-bordered hover-inset has-text-centered is-card-reveal"
                >
                  <div className="card-icon">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Idea-5"></i>
                    </div>
                  </div>
                  <div>
                    <div className="card-title">
                      <h4>{t("about.box_title_3")}</h4>
                    </div>
                    <div className="card-feature-description">
                      <span className=""></span>
                    </div>
                  </div>
                  <div className="button btn-align btn-more is-link color-primary mt-10 mb-10">
                    {t("other.more")} <i className="sl sl-icon-arrow-right"></i>
                  </div>
                </Link>
              </div>
              <div className="column">
                <Link
                  to="/about/professionals"
                  className="feature-card card-md is-startup light-bordered hover-inset has-text-centered is-card-reveal"
                >
                  <div className="card-icon">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Idea-5"></i>
                    </div>
                  </div>
                  <div>
                    <div className="card-title">
                      <h4>Ұстаздардың кәсіби дамуы</h4>
                    </div>
                    <div className="card-feature-description">
                      <span className=""></span>
                    </div>
                  </div>
                  <div className="button btn-align btn-more is-link color-primary mt-10 mb-10">
                    {t("other.more")} <i className="sl sl-icon-arrow-right"></i>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Мектеп жобалары */}
      {/* <div className="section jobalar__list">
        <div className="container">
          <div className="centered-title">
            <h2>Мектеп жобалары</h2>
            <div className="title-divider"></div>
            <div className="subheading">
              1-7 сынып оқушыларының оқыту және жеке тұлға ретінде дамыту бағдарламасы келесі
              жобаларды қамтиды:
            </div>
          </div>
          <div className="content-wrapper">
            <div className="columns is-vcentered pb-40 pt-40 mobile__direction">
              <div className="columns is-vcentered is-multiline">
                <div className="column is-6">
                  <div className="agency-icon-box">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Open-Book"></i>
                    </div>
                    <div className="box-title">{t("about.box_title_4")}</div>
                    <p className="box-content">{t("about.box_text_1")}</p>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="agency-icon-box">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Knight"></i>
                    </div>
                    <div className="box-title">{t("about.box_title_5")}</div>
                    <p className="box-content">{t("about.box_text_2")}</p>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="agency-icon-box">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Idea-5"></i>
                    </div>
                    <div className="box-title">{t("about.box_title_6")}</div>
                    <p className="box-content">{t("about.box_text_3")}</p>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="agency-icon-box">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Network"></i>
                    </div>
                    <div className="box-title">{t("about.box_title_7")}</div>
                    <p className="box-content">{t("about.box_text_4")}</p>
                  </div>
                </div>
              </div>
              <div className="column is-6">
                <img src={about_7} alt="" />
              </div>
            </div>
            <div className="columns is-vcentered pb-40 pt-40">
              <div className="column is-6">
                <img src={about_8} alt="" />
              </div>
              <div className="columns is-vcentered is-multiline">
                <div className="column is-6">
                  <div className="agency-icon-box">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Books"></i>
                    </div>
                    <div className="box-title">{t("about.box_title_8")}</div>
                    <p className="box-content">{t("about.box_text_5")}</p>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="agency-icon-box">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Running"></i>
                    </div>
                    <div className="box-title">{t("about.box_title_9")}</div>
                    <p className="box-content">{t("about.box_text_6")}</p>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="agency-icon-box">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Museum"></i>
                    </div>
                    <div className="box-title">{t("about.box_title_10")}</div>
                    <p className="box-content">{t("about.box_text_7")}</p>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="agency-icon-box">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Electric-Guitar"></i>
                    </div>
                    <div className="box-title">{t("about.box_title_11")}</div>
                    <p className="box-content">{t("about.box_text_8")}</p>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="agency-icon-box">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Notepad"></i>
                    </div>
                    <div className="box-title">{t("about.box_title_12")}</div>
                    <p className="box-content">{t("about.box_text_9")}</p>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="agency-icon-box">
                    <div className="is-icon-reveal">
                      <i className="im im-icon-Statistic"></i>
                    </div>
                    <div className="box-title">{t("about.box_title_13")}</div>
                    <p className="box-content">{t("about.box_text_10")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="section is-medium section__students">
        <div className="container">
          <div className="centered-title">
            <h2>Біздің оқушылар:</h2>
            {/* <div className="title-divider"></div> */}
            {/* <div className="subheading">{t("about.subtitle_5")}</div> */}
            <br />
          </div>

          <div className="content-wrapper">
            <div className="columns is-vcentered quick-feature-wrap">
              <div className="column is-6 centered-title">
                <div className="title quick-feature">
                  <h2 className="title is-4 clean-text">Ізденімпаз</h2>
                </div>
                <div className="title-divider"></div>
                <img src={about_9} alt="" />
              </div>
              <div className="column is-6 centered-title">
                <div className="title quick-feature">
                  <h2 className="title is-4 clean-text">Ойшыл</h2>
                </div>
                <div className="title-divider"></div>
                <img src={about_1} alt="" />
              </div>
            </div>
            <div className="columns is-vcentered quick-feature-wrap">
              <div className="column is-6 centered-title">
                <div className="title quick-feature">
                  <h2 className="title is-4 clean-text">Білімқұмар</h2>
                </div>
                <div className="title-divider"></div>
                <img src={about_4} alt="" />
              </div>
              <div className="column is-6 centered-title">
                <div className="title quick-feature">
                  <h2 className="title is-4 clean-text">Коммуникативті</h2>
                </div>
                <div className="title-divider"></div>
                <img src={about_8} alt="" />
              </div>
            </div>
            <div className="columns is-vcentered quick-feature-wrap">
              <div className="column is-6 centered-title">
                <div className="title quick-feature">
                  <h2 className="title is-4 clean-text">Ұстанымды</h2>
                </div>
                <div className="title-divider"></div>
                <img src={about_3} alt="" />
              </div>
              <div className="column is-6 centered-title">
                <div className="title quick-feature">
                  <h2 className="title is-4 clean-text">Кең ойлайтын</h2>
                </div>
                <div className="title-divider"></div>
                <img src={about_5} alt="" />
              </div>
            </div>
            <div className="columns is-vcentered quick-feature-wrap">
              <div className="column is-6 centered-title">
                <div className="title quick-feature">
                  <h2 className="title is-4 clean-text">Қамқоршыл</h2>
                </div>
                <div className="title-divider"></div>
                <img src={about_6} alt="" />
              </div>
              <div className="column is-6 centered-title">
                <div className="title quick-feature">
                  <h2 className="title is-4 clean-text">Тәуекелшіл</h2>
                </div>
                <div className="title-divider"></div>
                <img src={about_7} alt="" />
              </div>
            </div>
            <div className="columns is-vcentered quick-feature-wrap">
              <div className="column is-6 centered-title">
                <div className="title quick-feature">
                  <h2 className="title is-4 clean-text">Үйлесімді дамыған</h2>
                </div>
                <div className="title-divider"></div>
                <img src={about_2} alt="" />
              </div>
              <div className="column is-6 centered-title">
                <div className="title quick-feature">
                  <h2 className="title is-4 clean-text">Рефлексивті</h2>
                </div>
                <div className="title-divider"></div>
                <img src={about_10} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* download */}
      <div className="section calendar">
        <div className="container">
          <div className="centered-title">
            <h2>{t("about.calendar_title")}</h2>
            <div className="title-divider"></div>
            <div className="subheading">{t("about.calendar_subtitle")}</div>
          </div>

          <div className="calendar__block">
            <div id="flex-card" className="section-title-wrapper has-text-centered pt-30">
              <h2 className="title section-title has-text-centered dark-text text-bold">
                2023 оқу жылы
              </h2>
            </div>
            <div className="columns is-vcentered ">
              <div className="column is-10 is-offset-1">
                <table className="responsive-table is-accent">
                  <tbody>
                    <tr>
                      <th className="dark-text">Кыркуйек</th>
                      <th>Күні</th>
                      <th>Іс шара</th>
                      <th>Жауаптылар</th>
                    </tr>
                    <tr>
                      <td data-th="Кыркуйек"></td>
                      <td data-th="Күні">01.09</td>
                      <td data-th="Іс шара">Білім күні</td>
                      <td data-th="Жауаптылар">6 сынып</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">22.09</td>
                      <td data-th="Іс шара">Жәрмеңке</td>
                      <td data-th="Жауаптылар">Ағылшын кафедрасы</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні"></td>
                      <td data-th="Іс шара">ТЖ жаттығулар</td>
                      <td data-th="Жауаптылар">Сынып жетекшілер</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th className="dark-text">Қазан</th>
                      <th>Күні</th>
                      <th>Іс шара</th>
                      <th>Жауаптылар</th>
                    </tr>
                    <tr>
                      <td data-th="Қазан"></td>
                      <td data-th="Күні">02-03.10</td>
                      <td data-th="Іс шара">Видео-сьемка(Әдістер)</td>
                      <td data-th="Жауаптылар">Мұғалімдер</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">05.10</td>
                      <td data-th="Іс шара">Мұғалімдер күні</td>
                      <td data-th="Жауаптылар">4-сынып</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">14.10</td>
                      <td data-th="Іс шара">Family and marathon project</td>
                      <td data-th="Жауаптылар">5-сынып</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">20.10</td>
                      <td data-th="Іс шара">Challenge Көркемсөз</td>
                      <td data-th="Жауаптылар">Тілдер кафедрасы</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">27.10</td>
                      <td data-th="Іс шара">Өзге елге саяхат</td>
                      <td data-th="Жауаптылар">Барлық сынып</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th className="dark-text">Қараша</th>
                      <th>Күні</th>
                      <th>Іс шара</th>
                      <th>Жауаптылар</th>
                    </tr>
                    <tr>
                      <td data-th="Қараша"></td>
                      <td data-th="Күні"></td>
                      <td data-th="Іс шара">Шарықта Дарын жұлдыздары дайындық</td>
                      <td data-th="Жауаптылар"></td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th className="dark-text">Желтоқсан</th>
                      <th>Күні</th>
                      <th>Іс шара</th>
                      <th>Жауаптылар</th>
                    </tr>
                    <tr>
                      <td data-th="Желтоқсан"></td>
                      <td data-th="Күні">09.12</td>
                      <td data-th="Іс шара">Шарықта Дарын жұлдыздары дайындық - 2</td>
                      <td data-th="Жауаптылар"></td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">15.12</td>
                      <td data-th="Іс шара">Тәуелсіздік күніне тәрбие сағаты</td>
                      <td data-th="Жауаптылар">Барлық мұғалімдер</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">27-28.12</td>
                      <td data-th="Іс шара">Жаңа жыл</td>
                      <td data-th="Жауаптылар">4,7 сыныптар</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="calendar__block">
            <div id="flex-card" className="section-title-wrapper has-text-centered pt-30">
              <h2 className="title section-title has-text-centered dark-text text-bold">
                2024 оқу жылы
              </h2>
            </div>
            <div className="columns is-vcentered">
              <div className="column is-10 is-offset-1">
                <table className="responsive-table is-accent">
                  <tbody>
                    <tr>
                      <th className="dark-text">Қантар</th>
                      <th>Күні</th>
                      <th>Іс шара</th>
                      <th>Жауаптылар</th>
                    </tr>
                    <tr>
                      <td data-th="Қантар"></td>
                      <td data-th="Күні">19.01</td>
                      <td data-th="Іс шара">Директорлық бақылау жұмысы</td>
                      <td data-th="Жауаптылар">Математика кафедрасы</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th className="dark-text">Ақпан</th>
                      <th>Күні</th>
                      <th>Іс шара</th>
                      <th>Жауаптылар</th>
                    </tr>
                    <tr>
                      <td data-th="Ақпан"></td>
                      <td data-th="Күні">05-08.02</td>
                      <td data-th="Іс шара">1 тур олимпиада</td>
                      <td data-th="Жауаптылар">Барлық мұғалімдер</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">05-08.02</td>
                      <td data-th="Іс шара">Математика, ағылшын, жаратылыстану</td>
                      <td data-th="Жауаптылар">1-4 класс</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">05-08.02</td>
                      <td data-th="Іс шара">Математика, ағылшын, тарих</td>
                      <td data-th="Жауаптылар">5-7 класс</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">12-15.02</td>
                      <td data-th="Іс шара">2 тур олимпиада</td>
                      <td data-th="Жауаптылар">Барлық мұғалімдер</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">23.02</td>
                      <td data-th="Іс шара">Challenge Көркемсөз</td>
                      <td data-th="Жауаптылар">Тілдер кафедрасы</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th className="dark-text">Наурыз</th>
                      <th>Күні</th>
                      <th>Іс шара</th>
                      <th>Жауаптылар</th>
                    </tr>
                    <tr>
                      <td data-th="Наурыз"></td>
                      <td data-th="Күні">05-8.03</td>
                      <td data-th="Іс шара">8 Наурыз</td>
                      <td data-th="Жауаптылар">Барлық сынып</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th className="dark-text">Сәуір</th>
                      <th>Күні</th>
                      <th>Іс шара</th>
                      <th>Жауаптылар</th>
                    </tr>
                    <tr>
                      <td data-th="Сәуір"></td>
                      <td data-th="Күні">05.04</td>
                      <td data-th="Іс шара">Наурыз мерекесі</td>
                      <td data-th="Жауаптылар">1-2 сыныптар</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">18.04</td>
                      <td data-th="Іс шара">Емтихан</td>
                      <td data-th="Жауаптылар">1-7 сынып</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">23.04</td>
                      <td data-th="Іс шара">Challenge Көркемсөз</td>
                      <td data-th="Жауаптылар">Тілдер кафедрасы</td>
                    </tr>
                  </tbody>
                  <tbody>
                    <tr>
                      <th className="dark-text">Мамыр</th>
                      <th>Күні</th>
                      <th>Іс шара</th>
                      <th>Жауаптылар</th>
                    </tr>
                    <tr>
                      <td data-th="Мамыр"></td>
                      <td data-th="Күні">09.05</td>
                      <td data-th="Іс шара">Жеңіс күні</td>
                      <td data-th="Жауаптылар">6 сынып</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">18.05</td>
                      <td data-th="Іс шара">Family and marathon project</td>
                      <td data-th="Жауаптылар">3 сынып</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">25.05</td>
                      <td data-th="Іс шара">Соңғы қоңырау</td>
                      <td data-th="Жауаптылар">Барлық сынып</td>
                    </tr>
                    <tr>
                      <td data-th=""></td>
                      <td data-th="Күні">25.05</td>
                      <td data-th="Іс шара">Кітаптар жәрмеңкесі</td>
                      <td data-th="Жауаптылар">Кітапханашылар</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="features" className="section is-medium ib_pyp">
        <div className="container has-text-centered">
          <div className="section-title-wrapper has-text-centered">
            <div className="centered-title">
              <h2>
                Ізденімпаздар мен зерттеушілер <br /> мектебіне қош келдің!
              </h2>
            </div>
            <div className="centered-title">
              <div className="title-divider"></div>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="pb-20 pt-20 is-title-reveal">
              <Link
                className="button button-cta btn-align rounded raised primary-btn mb-20"
                to="/#mainForm"
              >
                {t("about.price_text_3")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
