import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import kz from "../resources/locales/kz";
import en from "../resources/locales/en";

i18next.use(initReactI18next).init({
  fallbackLng: "kz",
  resources: {
    kz,
    en,
  },
});
