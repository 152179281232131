import background from "../../resources/img/other/background.jpg";

const BooksPage = () => {
  return (
    <div className="books">
      <div
        className="hero parallax is-cover is-relative is-default is-bold"
        data-background={background}
        data-color="#000"
        data-color-opacity="0.3"
      >
        <div id="main-hero" className="hero-body pt-80 pb-80">
          <div className="container has-text-centered">
            <div className="columns is-vcentered is-centered">
              <div className="column is-8 has-text-centered is-header-caption">
                <h1 className="title is-spaced main-title is-2">
                  Электрондық
                  <br />
                  <span className="color__prime">кітапхана</span>
                </h1>
                <h2 className="subtitle is-5 light-text">Күш — білімде, білім — кітапта.</h2>
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section is-medium download">
        <div className="container">
          <div className="section-title-wrapper">
            <h2 className="title section-title has-text-centered dark-text">
              Электрондық кітаптар
            </h2>
          </div>
          <div className="content-wrapper">
            <div className="download__content">
              <a href="javascript:void(0)" className="download__item">
                <div className="download__icon is-icon-reveal color__prime">
                  <i className="im im-icon-Library-2"></i>
                </div>
                <div className="download__name">Ішкі тәртіп қағидалары</div>
              </a>
              <a href="javascript:void(0)" className="download__item">
                <div className="download__icon is-icon-reveal color__prime">
                  <i className="im im-icon-Library-2"></i>
                </div>
                <div className="download__name">Ішкі тәртіп қағидалары</div>
              </a>
              <a href="javascript:void(0)" className="download__item">
                <div className="download__icon is-icon-reveal color__prime">
                  <i className="im im-icon-Library-2"></i>
                </div>
                <div className="download__name">Ішкі тәртіп қағидалары</div>
              </a>
              <a href="javascript:void(0)" className="download__item">
                <div className="download__icon is-icon-reveal color__prime">
                  <i className="im im-icon-Library-2"></i>
                </div>
                <div className="download__name">Ішкі тәртіп қағидалары</div>
              </a>
              <a href="javascript:void(0)" className="download__item">
                <div className="download__icon is-icon-reveal color__prime">
                  <i className="im im-icon-Library-2"></i>
                </div>
                <div className="download__name">Ішкі тәртіп қағидалары</div>
              </a>
              <a href="javascript:void(0)" className="download__item">
                <div className="download__icon is-icon-reveal color__prime">
                  <i className="im im-icon-Library-2"></i>
                </div>
                <div className="download__name">Ішкі тәртіп қағидалары</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BooksPage;
