import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import $ from "jquery";

import teacher_bg from "../../resources/img/pages/teachers/all_full.jpeg";
import * as teachers from "./teachersPhoto";

const TeachersPage = () => {
  const { t } = useTranslation();
  const [sliderTrigger, setSliderTrigger] = useState(0);

  useEffect(() => {
    $(".slick-dots li").css("display", "none");
    $(".slick-active").css("display", "block");
    $(".slick-active")
      .prev()
      .css("display", "block")
      .prev()
      .css("display", "block")
      .prev()
      .css("display", "block");
    $(".slick-active")
      .next()
      .css("display", "block")
      .next()
      .css("display", "block")
      .next()
      .css("display", "block");
  }, [sliderTrigger]);

  const handleSliderClick = () => {
    setSliderTrigger(sliderTrigger + 1);
  };

  return (
    <div className="teachers">
      <div
        className="hero parallax is-cover is-relative is-default is-bold"
        data-background={teacher_bg}
        data-color="#000"
        data-color-opacity="0.3"
      >
        <div id="main-hero" className="hero-body pt-20 pb-20">
          <div className="container has-text-centered">
            <div className="columns is-vcentered is-centered">
              <div className="column is-8 has-text-centered is-header-caption">
                <h1 className="title is-spaced main-title is-2">
                  <br />
                  <span className="color__prime">{t("teachers.title_1")}</span>
                </h1>
                <h2 className="subtitle is-5 light-text">
                  Ақырын жүріп,анық бас,
                  <br />
                  Еңбегің кетпес далаға.
                  <br />
                  Ұстаздық қылған жалықпас,
                  <br />
                  Үйретуден балаға.
                  <div style={{ textAlign: "right" }}>
                    <br />
                    Абай Құнанбайұлы
                  </div>
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section is-medium teachers-boss">
        <div className="container">
          <div className="section-title-wrapper">
            <h2 className="title section-title has-text-centered dark-text">
              {t("teachers.title_2")}
            </h2>
            <div className="centered-title">
              <div className="title-divider"></div>
            </div>
          </div>
          <div className="content-wrapper max-w-center">
            <div className="image-grid teachers-boss-block">
              <div className="team-slanted-footer">
                <div className="columns is-vcentered">
                  <div className="column">
                    <figure className="team-slanted">
                      <img src={teachers.teacher1} alt="" />
                      <figcaption>
                        <h2>
                          Енкебаева <span>Гулжанар</span>
                        </h2>
                        <div className="member-position">Басқарушы директор</div>
                        {/* <!-- 
                          <p>
                            <a href="javascript:void(0)"><i className="fa fa-twitter"></i></a>
                            <a href="javascript:void(0)"><i className="fa fa-linkedin"></i></a>
                            <a href="javascript:void(0)"><i className="fa fa-facebook-f"></i></a>
                          </p>
                        --> */}
                      </figcaption>
                    </figure>
                  </div>
                  <div className="column">
                    <figure className="team-slanted">
                      <img src={teachers.teacher2} alt="" />
                      <figcaption>
                        <h2>
                          Сапарова <span>Гульнара</span>
                        </h2>
                        <div className="member-position">
                          Мектеп директорының оқу ісі жөніндегі орынбасары
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="column">
                    <figure className="team-slanted">
                      <img src={teachers.teacher3} alt="" />
                      <figcaption>
                        <h2>
                          Қалымбетова <span>Замира</span>
                        </h2>
                        <div className="member-position">
                          Мектеп директорының тәрбие ісі жөніндегі орынбасары
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="column">
                    <figure className="team-slanted">
                      <img src={teachers.teacher4} alt="" />
                      <figcaption>
                        <h2>
                          Ослякова <span>Екатерина</span>
                        </h2>
                        <div className="member-position">IB PYP координаторы, ағылшын тілі</div>
                      </figcaption>
                    </figure>
                  </div>
                  <div className="column">
                    <figure className="team-slanted">
                      <img src={teachers.teacher5} alt="" />
                      <figcaption>
                        <h2>
                          Бадильбекова <span>Шолпан</span>
                        </h2>
                        <div className="member-position">
                          Бастауыш сынып әдіскері, бағдарлама әзірлеушісі
                        </div>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section section-feature-grey is-medium no-padding-bottom teachers-slider">
        <div className="container">
          <div className="centered-title">
            <h2>{t("teachers.title_3")}</h2>
            <div className="title-divider"></div>
            <div className="subheading">{t("teachers.subtitle_3")}</div>
          </div>
          <div className="content-wrapper">
            <div className="columns">
              <div className="column is-10 is-12 no-padding-bottom">
                <div className="people-carousel" onClick={handleSliderClick}>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher1} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Енкебаева Гулжанар Жумахановна <br />
                          <span>
                            Мектептің негізін қалаушы, басқарушы директор, 6 сыныптарда Сингапур
                            математика мұғалімі
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Мектепті басқару, сапалы білім қызметін көрсету – мектептің стратегиялық
                            даму жоспарымен жүргізіледі. Басты мақсатымыз - оқушының бойында
                            концептуалды білім, құндылықтар мен қабілеттерді мемлекеттік және
                            халықаралық оқыту стандарты арқылы дамытуға барлық жағдай жасау. Осы
                            орайда әрбір оқушының дарындылығын анықтап қана қоймай, жоғарғы деңгейде
                            нәтиже көрсетуіне мұғалімдер ұжымы еңбек етуде.
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />
                            • 2009 жылы Leeds University қаржы магистрі, Болашақ бағдарламасы.
                            <br />
                            • 2016-2023 – Гарвард Жоғары Білім мектебінде жылдық бағдарлама бойынша
                            Certificate of advanced education leadership, Certificate in School
                            management and leadership. <br />• 2024 – Сулеймен Демирел Университеті,
                            математика, педагогикалық қайта даярлау курсы
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher2} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Сапарова Гульнара Тлепбергеновна
                          <span>
                            Бастауыш сынып мұғалімі, мектеп директорының оқу ісі жөніндегі
                            орынбасары «Самұрық 6, Қыран 6» сынып жетекшісі
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - оқушылардың ізденімпаздық, зерттеушілік қасиеттерін
                            дамыту арқылы білім сапасын арттыруды ұстанады.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог - сарапшы
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• ҚазМемПУ-
                            педагог-психолог, бастауыш білім беру.
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1.2022 жыл «Білім берудегі Менеджмент. Орта білім беру ұйымы басшысының
                            ұйымдастырушылық-педагогикалық қызметін жобалау және іске асыру» <br />
                            2.2022 жыл - "45- minute Lesson Upgrade: Effective Teaching Tools to
                            Develop 21st Century Skills in Students on Every Lesson" <br />
                            3. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1.USAID қоры , Khan Academy KZ ”БІЛІМ БЕРУ САЛАСЫНДАҒЫ ӨЗГЕРІСТЕР МЕН
                            ОҚЫТУДЫҢ ЖАҢА ӘДІСТЕМЕЛЕРІ” атты конференцияда ” "Как систематизировать
                            развитие учителей в школе?" тақырыбында өз тәжірибеңізбен бөлісіп,білім
                            саласының дамуына өз үлесін өз үлесін қосқаны үшін марапатталған. <br />
                            2. Қазақстан Республикасы Тәуелсіздігінің 30 жылдығына орай Талғар
                            ауданының әкімі Қ.Абдыхановтан Алғыс хат (2021 ж)
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher3} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Қалымбетова Замира Нұрланқызы
                          <span>Басқарушы директордың тәрбие ісі жөніндегі орынбасары </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Тіл дамыту – қазіргі оқытудың басты мәселесі. Эстетикалық пән – әдебиет
                            арқылы оқушылардың тілін дамыту, сөйлеу мәдениетін өрістету, сонымен
                            қатар, сөзді дұрыс қолдана білуге, өз ойын орнықты жеткізуге бағытталған
                            сабақ берудемшығармашылық ізденіспен қызмет етеді.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />
                            • "Орталық Азия Университеті" ЖШС, орыс тілі және әдебиеті пәнінің
                            мұғалімі (бакалавр) <br />• "Тұран" Университеті, заңгер (бакалавр)
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1."45- minute Lesson Upgrade: Effective Teaching Tools to Develop 21st
                            Century Skills in Students on Every Lesson" Лилия Мухаметшина <br />
                            2. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            "Конституцияға - 25 жыл" мемлекеттік медалі, ҚР Президенті Қ.Тоқаевтың
                            Алғыс хаты
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher4} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Ослякова Екатерина
                          <span>Ағылшын тілі пәні мұғалімі, IB PYP координаторы</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында – cабақта коммуникативті дағдыларды дамытып, оқушылардың
                            жан-жақты дамуына жағдай жасайды.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• Абылай хан атындағы
                            Қазақ Халықаралық Қатынастар және Әлем Тілдері Университеті
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1.TESOL (Teaching English to Speakers of Other Languages) from Arizona
                            State University <br />
                            2.TKT (Teaching Knowledge Test) Module 1, 2, 3; CLIL ; Teaching YL
                            <br />
                            3.The Online Professional English Network a program sponsored by the
                            U.S. Department of State with funding provided by the U.S. Government
                            and administered by FHI 360 – Teaching English to Young Learners <br />
                            4. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты <br />
                            5."45- minute Lesson Upgrade: Effective Teaching Tools to Develop 21st
                            Century Skills in Students on Every Lesson" Лилия Мухаметшина
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1.«Мектеп» бөлімінде ағылшын тілі мұғалімдеріне арналған халықаралық
                            Scholarium конференцияның спикері <br />
                            2.USAID қоры , Khan Academy KZ ”БІЛІМ БЕРУ САЛАСЫНДАҒЫ ӨЗГЕРІСТЕР МЕН
                            ОҚЫТУДЫҢ ЖАҢА ӘДІСТЕМЕЛЕРІ” атты конференцияда ”Жетістікке жеткізетін
                            қадамдар күнделігі арқылы құндылық тәрбиесін қалай жүргіземіз?
                            ”тақырыбында өз тәжірибеңізбен бөлісіп,білім саласының дамуына өз үлесін
                            өз үлесін қосқаны үшін марапатталған.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher5} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Бадилбекова Шолпан Шалхарқызы
                          <span>
                            Бастауыш сынып мұғалімі, бастауыш сыныптар әдіскері, бағдарлама
                            әзірлеушісі
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - оқушылардың шығармашылық қабілетін, өздік танымдық
                            біліктілігін дамыта отырып, білім сапасын арттыруды ұйымдастырады.
                            Оқушыларды зерттеу жұмыстарына кіріктіріп, жеке тұлға ретінде
                            қалыптастыруға еңбек етеді.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог - модератор
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />
                            • 2016 ж. Қазақ ұлттық қыздар педагогикалық университетінің «Бастауыш
                            оқытудың педагогикасы мен әдістемесі» мамандығы бойынша бакалавр түлегі.
                            <br />• 2018 ж. Аймақтық әлеуметтік-инновациялық университетінің
                            «Педагогика және психология» мамандығы бойынша магистр түлегі.
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1.Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты <br />
                            2.Ustaz Qurultay 22 - 16 сағаттық педагог шеберлігі семинарына қатысты.
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1.Қазақстан Республикасы Тәуелсіздігінің 30 жылдығына орай Талғар
                            ауданының әкімі Қ,.Абдыхановтан Алғыс хат (2021 ж)
                            <br />
                            2.Халықаралық интеллектуалды " Кенгуру - математика барлығы барлығы
                            үшін" онлайн-олимпиадасының жеңімпаздарының жетекшісі. <br />
                            3."Кемеңгер ұстаз" халықаралық онлайн - олимпиадасының 3 дәрежелі
                            жүлдегері.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher6} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Әлімқожақызы Аяжан
                          <span>Бастауыш сынып мұғалімі, "Қыран 1" сынып жетекшісі </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында – Әл - Фарабидің «Адамға ең бірінші білім емес, тәрбие
                            керек. Тәрбиесіз берілген білім – адамзаттың қас жауы, ол келешекте оның
                            өміріне қауіп әкеледі» деген сөзін алдыға қояды.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог - модератор
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• №1 Алматы қазақ
                            мемлекеттік гуманитарлық - педагогтік колледжі, бастауыш сынып мұғалімі
                            Қыздар Педагогикалық Университеті
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1.Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты <br />
                            2."Білім беруді жаңарту аясында оқушының жетістіктерін критериялды
                            бағалау <br />
                            3. Ustaz Qurultay 22 - 16 сағаттық педагог шеберлігі семинары <br />
                            4. "45- minute Lesson Upgrade: Effective Teaching Tools to Develop 21st
                            Century Skills in Students on Every Lesson" Лилия Мухаметшина
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            Қазақстан Республикасы Тәуелсіздігінің 30 жылдығына орай Талғар
                            ауданының әкімі Қ.Абдыхановтан Алғыс хат (2021 ж)
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher7} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Ордабайқызы Мақпал
                          <span>Бастауыш сынып мұғалімі, «Самұрық 1 » сынып жетекшісі</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - оқушылармен бірге зерттеу жұмыстарын жүргізіп, зерттеу
                            барысын қызықты өткізуді ұстанады. Сыныптың мәдениетін, дәстүрін
                            қалыптастыруға көңіл бөліп, оқушылардың бойында болу керек құндылықтарды
                            дамытуды мақсат етеді.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• Алматы қазақ
                            мемелекеттік гуманитарлық педагогтік колледжі, бастауыш сынып мұғалімі
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты <br />
                            2. "45-Minute Lesson Upgrade: Effective Teaching Tools to Develop 21
                            Century Skills in Students on Every Lesson" 03.11.2022ж.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher8} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Даркенбаева Азиза Серікқызы
                          <span>Бастауыш сынып мұғалімі, «Барыс 2» сынып жетекшісі</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - оқушылардың сабаққа деген қызығушылығын, ынтасын
                            оятып, танымдық белсенділіктерін арттыра отырып, шығармашылық
                            қабілеттерін дамытуға жағдай жасайды. Жас ұрпақты жан - жақты жетілген,
                            ақыл - парасатты, ой - өрісі биік, бәсекеге қабілетті азамат етіп
                            тәрбиелеуге өзінің үлесін қосып келеді.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог - модератор
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />
                            • 2008-2012 жылдары Есік гуманитарлық-экономикалық колледжінде “Бастауыш
                            сынып мұғалімі” мамандығы <br />• 2013-2016 жылдары Алматы
                            университетінде “Педагогика-психология” мамандығы
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1.Өрлеу “Жаңартылған білім беру мазмұны аясында оқыту сапасын көтерудегі
                            заманауй педагогикалық әдістер”оқытушыларының біліктілігін арттыру
                            курстарының білім беру бағдарламасы <br />
                            2.ҚР жалпы орта білім мазмұнын жаңарту шеңберінде бастауыш сынып
                            әдістемесі бойынша педагогика кадрларының біліктілігін арттыру білім
                            беру бағдарламсы бойынша қашықтықтан 72 академиялық сағат көлемінде
                            оқыту курсын аяқтады. <br />
                            3.Бастауыш сыныптарға арналған ҚРМББ стандартын Л.Г. Петерсонның Оқытуды
                            үйрену үздіксіз математика курсының үлгісін енгізуге арналған
                            72-сағаттық курсы
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1.‘’Үздік жыл мұғалімі - 2020‘’ байқауында 3 орын иеленген <br />
                            2.‘’Менің сүйікті ұстазым‘’ атты Республикалық қашықтықтан өткізілген
                            интернет – байқауында баланы болашаққа нақты және анық мақсат –
                            міндеттер қоя білуге,оның интелектуалды және шығармашылық әлеуетін
                            дамытуға қосқан үлесі үшін алғыс хаты <br />
                            3. Еңбекшіқазақ ауданының білім беру қызметкерлері арасында
                            ‘’Президенттік шақырым‘’жарысында 3 орын алғаны үшін марапатталған.
                            <br />
                            4. ‘’Нұрлы Жастар‘’ атты балалар мен жасөспірімдер арасындағы
                            Республикалақ көркем сөз,ән - би байқауына атсалысып өз деңгейінде
                            өтуіне үлкен үлес қосып,шәкірттерін ұлттық өнерді ұлықтауға баулығаны
                            үшін марапатталған.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher9} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Нұрмухамбетова Нұргул Нұрмухамбетқызы
                          <span>Бастауыш сынып мұғалімі, "Тұлпар 2" сынып жетекшісі.</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - балалардың коммуникативтік дағдысын дамыта отырып
                            ізденімпаз, білімқұмар, тәрбиелі, саналы оқушылар дайындауды алға қояды.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />
                            • Есік гуманитарлық-экономикалық колледжі, бастауыш білім беру <br />
                            • Алматы гуманитарлық-экономикалық Университеті, бастауыш білім беру
                            <br />
                            <span className="bold">Сертификаттар:</span> <br />
                            1. 45-Minute Lesson Upgrade: Effective Teaching Tools to Develop 21
                            Century Skills in Students on Every Lesson" <br />
                            2. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1.Талғар ауданы №30 орта мектеп директоры Д.Т.Аманжоловтан бастауыш
                            сынып апталығына орай берілген Алғыс хат <br />
                            2. Есік Жастар ресурстық орталығынан қоғамдық істерге белсенді қатысқаны
                            үшін Алғыс хат
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher10} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Ұзақбай Гулия Әбдікәрімқызы
                          <span>
                            Бастауыш сынып мұғалімі және кітапханашы. «Қыран 2» сынып жетекшісі.
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - оқушылармен бірге әр сабақтарын қызықты өткізіп.
                            Мектеп кітапханасының жұмыстарымен айналысады.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />
                            • Талғар педагогикалық - экономикалық колледжі, бастауыш сынып мұғалімі
                            <br />
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1."45- minute Lesson Upgrade: Effective Teaching Tools to Develop 21st
                            Century Skills in Students on Every Lesson" Лилия Мухаметшина <br />
                            2.Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher11} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Жумажанова Жанна Анарбековна
                          <span>Бастауыш сынып мұғалімі, «Барыс 3» сынып жетекшісі</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - оқушылармен зерттеу жұмыстарын қызықты, жан-жақты
                            өткізгенді ұстанады. Сыныптың мәдениетін, дәстүрін қалыптастыруға қатты
                            көңіл бөліп, оқушылардың бойында адами құндылықтарды дамытуды алдына
                            қояды.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог - сарапшы
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />
                            • ҚМҚПУ педагог-психолог (бакалавр) <br />• А.Қуатбеков атындағы ҚМДУ
                            бастауыш сынып мұғалімі (бакалавр)
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1.”Цифрлық сауаттылық негізіндері және ААЖ – да жұмыс істеу Kundelik.kz
                            ҚР жалпы білім беретін мектептерінің мұғалімдері үшін” тақырыбы бойынша
                            72 сағат көлемінде біліктілікті арттыру онлайн – курстарынан өтті.
                            <br />
                            2.Қазақстан Республикасы педагогтерінің біліктілігін арттырудың “Білім
                            берудегі Менеджмент.Орта білім беру ұйымы басшысының ұйымдастырушылық –
                            педагогикалық қызметін жобалау және іске асыру” бойынша 72 академиялық
                            сағат көлемінде білім беру бағдарламасы бойынша мұғалімдерді оқыту
                            курсын аяқтады.
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1.”Stars of Tamos” II Республикалық балалар мен жасөспірімдер арасындағы
                            өнер байқауында үздік нәтиже көрсеткені үшін жетекші ұстаз ретінде
                            марапатталған. <br />
                            2.VI Республикалық “Өнер алаңы”байқауында үздік шәкірт тәрбиелегеі үшін
                            марапатталды. <br />
                            3. USAID қоры , Khan Academy KZ ”БІЛІМ БЕРУ САЛАСЫНДАҒЫ ӨЗГЕРІСТЕР МЕН
                            ОҚЫТУДЫҢ ЖАҢА ӘДІСТЕМЕЛЕРІ” атты конференцияда ”Жетістікке жеткізетін
                            қадамдар күнделігі арқылы құндылық тәрбиесін қалай жүргіземіз?
                            ”тақырыбында өз тәжірибеңізбен бөлісіп,білім саласының дамуына өз үлесін
                            қосқаны үшін марапатталған. <br />
                            4. ҚР Мұқағали Мақатаев қоры “Соғады жүрек” республикалық байқауына
                            шәкір тәрбиелегені үшін Алғыс хат
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher12} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Итигулова Гульнур Ондасыновна
                          <span>Бастауыш сынып мұғалімі, «Самұрық 4» сынып жетекшісі</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - ерекше, қызықты, алған білімдерін өмірде пайдалана
                            алатындай, оның маңызын түсіріндіріп, табандылық құндылығы мен
                            ізденімпаз профайлын, креативті және критикалық ойлауды бойларында
                            дарыту мақсатында сабақтарды ерекше өткізеді.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог - модератор
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />
                            • Шымкент университеті, бастауыш сынып мұғалімі (бакалавр) <br />•
                            Шымкент университеті, көркем еңбек мұғалімі (бакалавр)
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1.Бастауыш сыныптарға арналған ҚРМББ стандартын Л.Г. Петерсонның Оқытуды
                            үйрену үздіксіз математика курсының үлгісін енгізуге арналған
                            72-сағаттық курсы <br />
                            2. " 45-Minute Lesson Upgrade: Effective Teaching Tools to Develop 21
                            Century Skills in Students on Every Lesson" <br />
                            3. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                          <p>
                            <span className="bold">Марапаттар:</span> <br />
                            1.USAID қоры , Khan Academy KZ ”БІЛІМ БЕРУ САЛАСЫНДАҒЫ ӨЗГЕРІСТЕР МЕН
                            ОҚЫТУДЫҢ ЖАҢА ӘДІСТЕМЕЛЕРІ” атты конференцияда ”Петерсон авторлығы
                            математика оқытудың тиімділігі ”тақырыбында өз тәжірибеңізбен
                            бөлісіп,білім саласының дамуына өз үлесін қосқаны үшін марапатталған.
                            <br />
                            2. ҚР Мұқағали Мақатаев қоры “Соғады жүрек” республикалық байқауына
                            шәкір тәрбиелегені үшін Алғыс хат <br />
                            3. Республикалық “Дара бала” журналының ұйымдастыруымен “Кел, балалар,
                            оқылық” еркін тақырып “Көркемсөз” аталымы бойынша Дарын инновациялық
                            мектебінің Самұрық 4 GROUP сынып оқушыларын қатыстырған жетекшісі Алғыс
                            хатпен марапатталады
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher13} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Берікбай Жанерке Ертайқызы
                          <span>Бастауыш сынып мұғалімі, “Барыс 4” сынып жетекшісі</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - бірлікті, достықты дамытып, оқушылар бойынан мектеп
                            құндылықтарын үйретіп жүр.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• №1 Алматы қазақ
                            мемлекеттік гуманитарлық-педагогтік колледжі, Бастауыш сынып мұғалімі
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1. "45- minute Lesson Upgrade: Effective Teaching Tools to Develop 21st
                            Century Skills in Students on Every Lesson" Лилия Мухаметшина <br />
                            2. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1. USAID қоры , Khan Academy KZ ”БІЛІМ БЕРУ САЛАСЫНДАҒЫ ӨЗГЕРІСТЕР МЕН
                            ОҚЫТУДЫҢ ЖАҢА ӘДІСТЕМЕЛЕРІ” атты конференцияда ”Әдебиет пәні ерекшелігі”
                            тақырыбында өз тәжірибеңізбен бөлісіп,білім саласының дамуына өз үлесін
                            қосқаны үшін марапатталған. <br />
                            2. ⁠ҚР Мұқағали Мақатаев қоры “Соғады жүрек” республикалық байқауына
                            шәкір тәрбиелегені үшін Алғыс хат <br />
                            3. Талғар аудандық "MEGABRAIN" бірінші математика-логикалық
                            олимпиадасына қатысушыларды дайындап, қатысуына ықпал еткені үшін Алғыс
                            хатпен марапатталған.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher14} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Қанапьянова Әсемгүл Ерболқызы
                          <span>"Қыран"5 ,"Самұрық" 5 сынып жетекшісі.</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - оқушылармен логикалық барысын дамытып,үнемі ізденіс
                            жүргізу.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог - модератор.
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• Академик Ә.Қуатбеков
                            атындағы халықтар достастығы университеті, бастауыш сынып мұғалімі
                            (бакалавр)
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1.Қ.Р-ның орта білім мазмұнын жаңарту шеңберінде қазақ тілінде оқытатын
                            мектептердегі бастауыш сынып бойынша педагогика кадрларының біліктілігін
                            арттыру білім беру бағдарламасы бойынша 120 академиялық сағат
                            көлеміндегі оқыту курсы <br />
                            2. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты <br />
                            3.Бастауыш сыныптарға арналған ҚРМББ стандартын Л.Г. Петерсонның Оқытуды
                            үйрену үздіксіз математика курсының үлгісін енгізуге арналған
                            72-сағаттық курсы <br />
                            4."45- minute Lesson Upgrade: Effective Teaching Tools to Develop 21st
                            Century Skills in Students on Every Lesson" Лилия Мухаметшина <br />
                            5."Қысқы математикалық ойындар 2023 "олимпиадасы барысында өткізілген
                            "Дарынды балаларды оқытудағы қолданылатын әдіс-тәсілдер" семинары
                          </p>
                          <p>
                            <span className="bold">Марапаттар:</span> <br />
                            1.Қызмет барысындағы нәтижелері үшін халықаралық зияткерлік байқауларға,
                            соның ішінде "BIlgen BAIGE" математикалық ұлттық білім беру сайыстарына
                            оқушыларды дайындағаны үшін құрмет грамотасы <br />
                            2.Іле ауданының білім басшысы Л.Каткееваның Мақтау қағазы <br />
                            3.Қазақстан Республикасы Тәуелсіздігінің 30 жылдығына орай,Талғар
                            ауданының әкімі Қ,.Абдыхановтан Алғыс хат <br />
                            4.VІ Республикалық "Өнер алаңы " байқауында үздік шәкірт тәрбиелегені
                            үшін Мақтау қағазы <br />
                            5. Талғар аудандық "MEGABRAIN" бірінші математика-логикалық
                            олимпиадасына қатысушыларды дайындап, қатысуына ықпал еткені үшін Алғыс
                            хатпен марапатталған.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher15} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Матан Галима
                          <span>
                            Биология пән мұғалімі, «Тұлпар 7» сынып жетекшісі <br /> Қыран 5,
                            Самұрық 5, Қыран 6, Самұрық 6, Тұлпар 7 сынып оқушыларына жаратылыстану,
                            биология пәнінен береді
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - оқушыларды жан-жақты тәрбиелеудегі мәдениетін,оқушының
                            жеке тұлға ретінде қалыптасуын,оқушыларды танымдық қызығушылығын
                            арттыруды жөн көреді.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• Биология, Абай атындағы
                            Қазақ ұлттық педагогикалық университеті
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1. "Білім беру мен ақпараттық ортаның жаңаруы жағдайында тұлғаны
                            әлеуметтендірудің және тәрбиелеудің жаңа мазмұнын ендіру" <br />
                            2. " 45-Minute Lesson Upgrade: Effective Teaching Tools to Develop 21
                            Century Skills in Students on Every Lesson" <br />
                            3. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1. Алғыс хат. "Алтын білім кілті" әдістемелік-ғылыми зерттеу орталығының
                            директорынан. <br />
                            2. Қазақстан Республикасы Тәуелсіздігінің 30 жылдығына орай,Талғар
                            ауданының әкімі Қ.Абдыхановтан Алғыс хат
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher16} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Асылбек Эльдана
                          <span>
                            Ағылшын тілі пәні мұғалімі. Тұлпар 2, Қыран 2, Барыс 2, Барыс 3
                            сыныптарына ағылшын тілі мен Сингапур математикасын ағылшын тілінде
                            өтеді.
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында шығармашылықпен жұмыс жасап, оқушылар бойында
                            ізденімпаздық, шығармашылық, мейірімділік қасиеттерін дамытуда. Оқу
                            үдерісенде оқушылармен сырлас досындай жақындық көрсетіп, жақсы
                            атмосфера орнатып жұмыс істегенді жақсы көреді.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• Абылай хан атындағы
                            Қазақ Халықаралық Қатынастар және Әлем Тілдері Университеті, Шетел тілі:
                            екі шетел тілі (бакалавр)
                          </p>
                          <p>
                            <span className="bold">Сертификаттары:</span> <br />
                            1."45- minute Lesson Upgrade: Effective Teaching Tools to Develop 21st
                            Century Skills in Students on Every Lesson" Лилия Мухаметшина <br />
                            2. "Scoring IELTS Writing Task 2" Scholarium ұйымы <br />
                            3. "Methodology, CPD, Blogging" Scholarium ұйымы <br />
                            4. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                          <p>
                            <span className="bold">Марапаттар:</span> <br />
                            1."Алтын-білім кілті" әдістемелік зерттеу орталығынан алғыс хат <br />
                            2.Оқушылардың интеллектуалды қабілеттері арқылы адал бәсекелестік пен
                            табандылықты дамыту мақсатында Талғар аудандық "WISE OWL" ағылшын
                            олимпиадасына <br />
                            қатысушыларды дайындап, қатысуына ықпал еткені үшін алғыс хат
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher17} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Керімбай Аяжан Қанатқызы
                          <span>
                            Ағылшын тілі пәні мұғалімі. Самұрық 1, Қыран 1, Барыс 4, Самұрық 5
                            сыныптарына ағылшын тілі мен Сингапур математикасын ағылшын тілінде
                            өтеді.
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында – оқушылардың шығармашылық қабілетін дамыта отырып,
                            өркениет көшіне жол бастайтын, ұлттық рухты бойына сіңірген саналы да
                            білімді ұрпақты тәрбиелеуді алдына қояды.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• Абылай хан атындағы
                            Қазақ Халықаралық Қатынастар және Әлем Тілдері Университеті, Шетел тілі:
                            екі шетел тілі (бакалавр)
                          </p>
                          <p>
                            <span className="bold">Сертификаттары:</span> <br />
                            1.Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1.Оқушылардың интеллектуалды қабілеттері арқылы адал бәсекелестік пен
                            табандылықты дамыту мақсатында Талғар аудандық "WISE OWL" ағылшын
                            олимпиадасына қатысушыларды дайындап, қатысуына ықпал еткені үшін алғыс
                            хат
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher18} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Бибекова Балжан
                          <span>
                            Ағылшын пәні мұғалімі.
                            <br />
                            Барыс 3,Тұлпар 6, Самұрық 4, Барыс 4, Самұрық 5, Қыран 5 сыныптарына
                            Сингапур математикасын ағылшын тілінде және Самұрық 4 сыныбына ағылшын
                            тілі сабағын өтеді.
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - ағылшын сабағында айтылым, көбірек қысылмай сөйлеу
                            және күнделікті өтілген сөздерді қолдана білу. Оқушылармен жұмыс кезінде
                            жарыс және ойын түрінде жұмыс жасағанды ұнатады, оқушылардың қызығы
                            артып белсенді сабаққа қатысуы мотивация береді.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• Абай атындағы Қазақ
                            ұлттық педагогикалық университетінің түлегі, Шетел тілі: екі шетел тілі
                            (бакалавр)
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1.Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1.Оқушылардың интеллектуалды қабілеттері арқылы адал бәсекелестік пен
                            табандылықты дамыту мақсатында Талғар аудандық "WISE OWL" ағылшын
                            олимпиадасына қатысушыларды дайындап, қатысуына ықпал еткені үшін алғыс
                            хат
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher19} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Буенбаева Ақжан Айтқалиқызы
                          <span>
                            Қыран 5, Қыран 6 , Самұрық 6, Тұлпар 7 сыныптарының ағылшын тілі пәні
                            мұғалімі.
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - тілді үйрету, мәдениетке деген қызығушылықты
                            көбейтуге, коммуникативтік дағдыларын дамытуға тырысады.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог - модератор
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />
                            • Қазақ Халықаралық Қатынастар және Әлем Тілдер Университетінің “Шетел
                            филологиясы” (бакалавр) <br />• Абай атындағы Қазақ Ұлттық Педагогикалық
                            Университетінің “Екі шет тілі” (магистр)
                          </p>
                          <p>
                            <span className="bold">Сертификаттары:</span> <br />
                            1."45- minute Lesson Upgrade: Effective Teaching Tools to Develop 21st
                            Century Skills in Students on Every Lesson" Лилия Мухаметшина <br />
                            2. Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1.Қазақстан Республикасы Тәуелсіздігінің 30 жылдығына орай,Талғар
                            ауданының әкімі Қ.Абдыхановтан Алғыс хат <br />
                            2.Оқушылардың интеллектуалды қабілеттері арқылы адал бәсекелестік пен
                            табандылықты дамыту мақсатында Талғар аудандық "WISE OWL" ағылшын
                            олимпиадасына қатысушыларды дайындап, қатысуына ықпал еткені үшін алғыс
                            хат
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher20} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Қуанған Әсима
                          <span>
                            Физика, математика мұғалімі <br />
                            Қырын 6, Самұрық 6 сыныбының математика, Тұлпар 7 сыныбының алгебра,
                            геометрия, физика пәндерін береді.
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - физика мен математика арасындағы тәжірибелік
                            сабақтарды негізге алып, жаратылыстану пәндерінің қоғаммен байланысын
                            түсіндіруге аса мән беріп, оқушылардың тұлғалық және инновациялық
                            дамуына тәрбиелейтін үздік оқытушы.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• ҚХР “University
                            TianJin”-де Университеті, Физика, математика мұғалімі
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1.2015 жылы “Физика зертхана меңгерушісі” <br />
                            2."45-Minute Lesson Upgrade: Effective Teaching Tools to Develop 21
                            Century Skills in Students on Every Lesson" 03.11.2022ж. <br />
                            3.Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1.2013 жылы білім асырып , “Тіректі математика оқытушысы” болып
                            марапатталған . <br />
                            2.Талғар аудандық "MEGABRAIN" бірінші математика-логикалық олимпиадасына
                            қатысушыларды дайындап, қатысуына ықпал еткені үшін Алғыс хатпен
                            марапатталған.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher21} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Торехан Айбол Миятұлы
                          <span>1 – 7 сыныптарға робототехника үйірме тренері</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - оқушылардың ізденімпаздық, зерттеушілік қасиеттерін
                            дамыту арқылы білім сапасын арттыруды ұстанады. Инновациялық шешімдер
                            робототехника саласының дамуын ықпал етуін оқушылардың күнделікті
                            сабақтарында маңыздылығын түсіндіріп, топтық жобалар жасауға әсер етеді.
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />
                            • Алматы индустриалды колледжі <br />• Тараз инновациялық-гуманитарлық
                            университеті
                          </p>
                          <p>
                            FIRST Championship - FIRST LEGO League Challenge-Explore жарыстарына
                            оқушыларды дайындап, жарыстарға қатыстырып, жүлделі орындар алғаны
                            нәтижесінде бірнеше марапаттары, алғыс хаттары бар
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher22} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Спанова Мадина Маратқызы
                          <span>
                            Тарих пәнінің мұғалімі <br />
                            Самұрық 5, Қыран 5, Самұрық 6, Қыран 6, Тұлпар 7 сыныптарына тарих
                            пәнінен береді.
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - кіші қоғам мектептен басталады, сол себепті оқушыларды
                            патриоттық сезімге тәрбиелеу басты міндеті деп сенеді. «Ұстаздың ең
                            жоғары өнері – бұл шығармашылық көріністе және білім алуда қуаныш ояту
                            қабілеті» - Альберт Эйнштейн. Мен үшін шабыттың қайнар көзі – балалардың
                            оқу үрдісіндегі қуанышы мен өзін-өзі көрсетуі, сонымен қатар балалардың
                            алған білімдерін меңгеруі және оны өмірде қолдануы.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> тарих мамандарын даярлау <br />
                            • 2015-2019 жж. – атындағы ҚазҰУ түлегі. Әл Фараби. <br />• 2019-2021
                            Әл-Фараби атындағы ҚазҰУ тарих ғылымдарының магистрі
                          </p>
                          <p>
                            <span className="bold">Сертификаттар:</span> <br />
                            1.Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі: Action
                            Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері»
                            ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher23} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Нүсіпов Нұрлы Нұрланұлы
                          <span>Каратэ үйірмесінің бапкері</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - халықаралық және де басқа көптеген жарыстардың
                            жеңімпаздары мен жүлдегерлерін тәрбиелеп, болашақта әлемдік деңгейдегі
                            додаларда да топ жарады деген сенімде.
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• 2005-2009 жылы Қазақтың
                            спорт және туризм Академиясында шығыс жекпежек факультетінде каратэ
                            бөлімін үздік аяқтап, спорт шебері атағы берілді, сонымен қатар бокстан
                            кмс дәрежесіне де ие болған. 2010 жылдан бастап бапкерлік саласында.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher24} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Сатыбалдинова Аяжан Жанатқызы
                          <span>Хореография үйірмесінің мұғалімі.</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - оқушыларды еңбекке баулып ,этика және эстатикалық
                            тәрбие береді. Балаларды төзімділікке, шыдамдылыққа, еңбеккорлыққа,
                            мәдениетке үйретеді.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> Хореограф
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• Жүсіпбек Елебеков
                            атындағы Республикалық эстрадалық-цирк колледжі.
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            1.Бери Выше - Топовый хореограф :Танцуют все <br />
                            2.2018 жылғы Грузия, Батуми би конкрусының жеңімпазы. <br />
                            3.DUBAI EAST VISION 2020 Халықаралық өнер фистивальдың жеңімпазы .
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher25} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Кубеева Гулнур Жарылкаповна
                          <span>
                            АРТ студио пәнінің мұғалімі. <br />1 – 7 сыныптарына АРТ студио
                            сабағынан береді
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - оқушылардың шығармашылық қабілеттерін арттыру,
                            қызығушылықтарын жан жақты ашу басты мақсаты болып табылады.
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• Алматы Сәулет-құрылыс
                            Академсын (қазіргі таңда КазГАСА) тәмәмдаған.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher26} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Сейсен Сәрсенбекұлы
                          <span>
                            Музыка пәнінің мұғалімі <br /> Мектепте 1- 7 сыныптарының музыка сабағын
                            береді.
                          </span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Сабақ барысында - баға жетпес эстетикалық өмір құралы болған домбыра
                            арқылы оқушылардың бойындағы қабілетін оятып, осы заман талаптарына сай
                            келетін, жоғары саналы, жан-жақты дамыған, білікті домбыра
                            орындаушыларды тәрбиелеуді мақсат еткен тәжірибелі ұстаз.
                          </p>
                          <p>
                            <span className="bold">Санаты:</span> педагог
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />• 1999 жылы ҚХР Шинжаң
                            көркемөнер мектебінің домбыра мамандығын бітірген.
                          </p>
                          <p>
                            <span className="bold">Марапаттары:</span> <br />
                            2002 жылы ҚХР Шинжаң Өнер Одағының "шертпе күй шебері"марапатын алған.{" "}
                            <br />
                            2015 жылы ҚХР “Орталық түркі тілдес ұлттар әзілдері ұйымының" тұрақты
                            мүшесі болған.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher27} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Дюлембаева Мадиана Ержановна
                          <span>Мектеп медбике қызметкері</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            Өз жұмысы барысында – оқушылырдың денсаулығын басты назарда ұстап, ата –
                            аналармен тығыз қарым қатынаста болғанды жөн деп есептейді.
                          </p>
                          <p>
                            <span className="bold">Мамандығы:</span> <br />
                            • 2013 жылы “Талғар медицина колледжі” МҚК – ның “Емдеу ісі” мамандығы
                            бойынша бітіріп шықты. Біліктілік комиссияының шешімімен оған – Фельдшер
                            біліктілігі берілді. <br />
                            • 31.12.2014 жылы Біліктілігін жоғарлатқаны туралы 108 сағаттық куәлігін
                            алған. <br />
                            • 16.04.2018 жылы Біліктілігін жоғарлатқаны туралы 108 сағаттық куәлігін
                            алған. <br />• 08.12.2023 жылы Біліктілігін жоғарлатқаны туралы 120
                            сағаттық куәлігін алған.
                          </p>
                          <p>
                            <span className="bold">Сертификаттары:</span>
                            <br />
                            1. 2013 жылы Қазақстан Республикасының Денсаулық сақтау министірлігі
                            Қоғамдық денсаулық сақтау комитетінің Алматы қаласының қоғамдық
                            денсаулық сақтау департаментінің растауымен: Сестринское дело
                            (медицинская сестра) общей практики,специализированная медицинская
                            сестра бағытында жұмыс істеуге рұқсат алған. <br />
                            2. 2018 жылы Қазақстан Республикасының Денсаулық сақтау министірлігі
                            Қоғамдық денсаулық сақтау комитетінің Алматы қаласының қоғамдық
                            денсаулық сақтау департаментінің растауымен: Сестринское дело
                            (медицинская сестра) общей практики,специализированная медицинская
                            сестра бағытында жұмыс істеуге рұқсат алған.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                  <div className="people-carousel-item">
                    <div className="item-wrapper">
                      <div className="image-column">
                        <div className="carousel-img">
                          <img src={teachers.teacher28} alt="" />
                        </div>
                      </div>
                      <div className="content-column">
                        <h2 className="testimonial-title">
                          Абылкаева Сандуғаш
                          <span>Мектеп администраторы</span>
                        </h2>
                        <div className="title-divider"></div>
                        <div className="testimonial-content">
                          <p>
                            2022 жылдан бері Дарын инновациялық мектебінде админстратор болып жұмыс
                            істейді. <br />
                            Мектепте оқушыларды, ата - аналарды қарсы алу,ата-аналарға мектеп туралы
                            толық мәлімет беріп, оқушылардың құжаттарын қабылдайды.
                          </p>
                        </div>
                        <div className="mobile-spacer is-hidden-desktop is-hidden-tablet"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeachersPage;
