import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import * as Pages from "../../pages";
import { useEffect } from "react";
import BulkitScripts from "../../utils/BulkitScripts";

const AppRouter = () => {
  const location = useLocation();
  // Скролл компонентов наверх при смене страницы
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    // Подгружает скрипты при переходе по страницам
    // console.log("Маршрут изменен", location.pathname);
    BulkitScripts(false);
    return () => {
      // console.log("Размотирование");
      BulkitScripts(true);
    };
  }, [location.pathname]);

  return (
    <Routes>
      <Route path="/" element={<Pages.MainPage />} />
      <Route path="/about" element={<Pages.AboutPage />} />
      <Route path="/politics" element={<Pages.PoliticsPage />} />
      <Route path="/gallery" element={<Pages.GalleryPage />} />
      <Route path="/books" element={<Pages.BooksPage />} />
      <Route path="/vacancies" element={<Pages.VacanciesPage />} />
      <Route path="/faq" element={<Pages.FAQ />} />
      <Route path="/teachers" element={<Pages.TeachersPage />} />
      <Route path="/contacts" element={<Pages.ContactsPage />} />
      <Route path="/about/mugs" element={<Pages.MugsPage />} />
      <Route path="/about/system" element={<Pages.SystemPage />} />
      <Route path="/about/social" element={<Pages.SocialPage />} />
      <Route path="/about/professionals" element={<Pages.ProfessionalsPage />} />
      <Route path="/gallery/album-1" element={<Pages.Album01 />} />
      <Route path="/gallery/album-2" element={<Pages.Album02 />} />
      <Route path="/gallery/album-3" element={<Pages.Album03 />} />
      <Route path="/gallery/album-4" element={<Pages.Album04 />} />
      {/* <Route path="/gallery/:id" element={<Pages.Album />} /> */}
      <Route path="/about/calendar-2023" element={<Pages.Calendar2023 />} />
      <Route path="/about/calendar-2024" element={<Pages.Calendar2024 />} />
      <Route path="*" element={<Pages.ErrorPage />} />
    </Routes>
  );
};

export default AppRouter;