import { useTranslation } from "react-i18next";
import logo from "../../../resources/img/logo/logo_kz.png";
import { Link } from "react-router-dom";

const AppFooter = () => {
  const { t } = useTranslation();
  return (
    <footer className="footer footer-dark-left">
      <div className="container">
        <div className="columns is-vcentered">
          <div className="column is-4">
            <div className="mb-20">
              <img className="small-footer-logo switcher-logo" src={logo} alt="" />
            </div>
            <div>
              <span className="moto">
                {t("other.ps_1")}
                &nbsp; <i className="fa fa-heart color-red"></i> &nbsp;
                <a href="https://zero.com.kz" target="_blank">{t("other.ps_2")}</a>                
              </span>
              <nav className="level is-mobile mt-20">
                <div className="level-left level-social">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/darynimektebi/"
                    className="level-item"
                  >
                    <span className="icon">
                      <i className="fa fa-instagram"></i>
                    </span>
                  </a>
                  <a
                    className="level-item"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/@daryn2019"
                  >
                    <span className="icon">
                      <i className="fa fa-youtube"></i>
                    </span>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://wa.me/+77054906531"
                    className="level-item"
                  >
                    <span className="icon">
                      <i className="fa fa-whatsapp"></i>
                    </span>
                  </a>
                </div>
              </nav>
            </div>
          </div>
          <div className="column">
            <div className="footer-nav-right">
              <Link className="footer-nav-link" to="/">
                {t("header.menu_1")}
              </Link>
              <Link className="footer-nav-link" to="/about">
                {t("header.menu_2")}
              </Link>
              <Link className="footer-nav-link" to="/politics">
                {t("header.menu_3")}
              </Link>
              <Link className="footer-nav-link" to="/gallery">
                {t("header.menu_4")}
              </Link>
              <Link className="footer-nav-link" to="/teachers">
                {t("header.menu_5")}
              </Link>
              <Link className="footer-nav-link" to="/FAQ">
                {t("header.menu_6")}
              </Link>
              <Link className="footer-nav-link" to="/contacts">
                {t("header.menu_7")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default AppFooter;
