import Breadcrumbs from "../../../components/layouts/breadcrumbs/Breadcrumbs";

import social_1 from "../../../resources/img/pages/social/social_1.jpg";
import social_2 from "../../../resources/img/pages/social/social_2.jpg";
import social_3 from "../../../resources/img/pages/social/social_3.jpg";
import social_4 from "../../../resources/img/pages/social/social_4.jpg";
import social_5 from "../../../resources/img/pages/social/social_5.jpg";
import instagram from "../../../resources/img/icons/social/instagram.svg";

const SocialPage = () => {
  return (
    <div className="social about__child">
      <div className="section">
        <div className="container">
          <Breadcrumbs />
          <div className="section-title-wrapper has-text-centered mb-40">
            <div className="bg-number"></div>
            <h2 className="section-title-landing">Әлеуметтік жобалар</h2>
          </div>

          <div className="content-wrapper tabbed-features">
            <div className="columns is-vcentered">
              <div className="column">
                <div className="navigation-tabs outlined-pills animated-tabs">
                  <div className="tabs is-centered">
                    <ul>
                      <li className="tab-link is-active" data-tab="social_1">
                        <a>Әлеуметтік желідегі жобалар</a>
                      </li>
                      <li className="tab-link" data-tab="social_2">
                        <a>Отбасылық ынтымақтастық</a>
                      </li>
                      <li className="tab-link" data-tab="social_3">
                        <a>Дарын жұлдыздары</a>
                      </li>
                      <li className="tab-link" data-tab="social_4">
                        <a>«Ұшқыр ой» дебат алаңы</a>
                      </li>
                      <li className="tab-link" data-tab="social_5">
                        <a>Адвент күнтізбе</a>
                      </li>
                    </ul>
                  </div>
                  <div id="social_1" className="navtab-content is-active">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={social_1} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">
                            <a
                              href="https://www.instagram.com/reel/CzYmdTBixKF/?igshid=NzBmMjdhZWRiYQ%3D%3D"
                              target="_blank"
                              rel="noreferrer"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              Әлеуметтік желідегі жобалар &nbsp;
                              <img src={instagram} alt="instagram" />
                            </a>
                          </h2>
                          <br />
                          <p className="body-color">
                            Білімгерлер Small talks, Daryn news, Thinkers time әлеуметтік желідегі
                            жобаларға қатысу арқылы жан-жақтылығы мен қоғамда өзін-өзі дұрыс
                            ұсынуды, өз ойын ашық айту дағдыларын үйрену мен қатар, тіл байлығын
                            кеңейтеді. Аталған жобалар мектеп жаңалықтарының әралуандығын, зерттеу
                            сұрағының нәтижелері мен ағылшын тілін меңгеру деңгейінің сапасын
                            көрсетеді.
                          </p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="social_2" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={social_2} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Отбасылық ынтымақтастық</h2>
                          <p className="body-color"></p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Оқу жылында 2 рет мектебіміздің ұйымдастыруымен «ENERGIZE
                                PROJECT&SPORT MARATHON» өткізіледі. Марафон екі бөлімнен тұрады.
                                *1ші бөлімде - мектеп қабырғасындағы зерттеу жүргізу жұмыстарының
                                жалғасы ретінде ENERGIZE PROJECT-ке ата-аналар мен оқушылар бірлесіп
                                экожоба ұсынып, қорғайды.
                              </div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                *2ші бөлімінде салауатты өмір салтын нығайтуға бағытталған спорттық
                                жарыстар ұйымдастырылып, жеңімпаздарға арнайы кубоктар мен дипломдар
                                беріледі. br Сонымен қатар, «Бизнес» және «Кітап» жәрмеңкесі де
                                өткізіледі. Бұл жәрмеңкеде оқушылар өздері жасаған заттарын, яғни
                                тамақ өнімдері, қолөнер бұйымдары мен кітаптарды әкеліп, сатуға
                                шығарады. Жәрмеңкеге қатысушының - сату мен сатып алу кезінде
                                көшбасшылық, дербестік, тіл табыса білу және жауапкершілік сияқты
                                қасиеттерді дамытып қана қоймай, сатуға арналған өнімді таңдау, оның
                                қажеттілігін талдау, тауарды әдемі орау, өз жұмыс орнын әрлеу, өз
                                тауарын таныстыра білу, оның артықшылықтарын көрсету және
                                сәтсіздікке мойымай, жақсы нәтижеге сене білуді үйретеді.
                              </div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                «Мамандығым – мақтанышым» айдары аясында оқушыларымыздың ата-аналары
                                мектепке келіп, өзінің мамандығы мен жұмыс орны туралы айтып,
                                оқушылармен ашық пікір алаңында ой алмасады. Әр тоқсанның
                                қорытындысы туралы әр сабақтың бағалары мен дағдыларының дамуы
                                туралы тоқсандық есеп жасақталады. Тоқсанның соңындағы ата-аналар
                                жиналысына барлық ата-аналар дайын ұсыныстары мен сұрақтарымен
                                келеді. Жоғарыда аталған шараларды өткізу арқылы мектеп – оқушы -
                                ата-ана қарым-қатынасының дұрыс бағытын қалыптастырушы фактор
                                ретінде танылады.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="social_3" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={social_3} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Дарын жұлдыздары</h2>
                          <br />
                          <p className="body-color">
                            ІВ халықаралық бағдарламасындағы мектептің даму саясатына сәйкес білім,
                            тәрбие, дағды мен зерттеу нәтижелерінің қорытындысы - «Шарықта, Дарын
                            жұлдыздары» концерті өткізілді. Салтанатты шараның басты айрықша белгісі
                            – 32 номерге мектептің барлық оқушылары мен ұстаздар ұжымы толық
                            қатысып, сахна төрінде көрінді. Концертте өнер көрсетумен қатар, барлық
                            академиялық білім беру мен спорттық жетістіктер көрсетілді.
                          </p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="social_4" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={social_4} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">«Ұшқыр ой» дебат алаңы</h2>
                          <p className="body-color"></p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color">
                                Оқушылардың бойында белсенді азаматтық ұстанымын қалыптастыру, жас
                                ұрпақтың бойында өзін-өзі тәрбиелеу қажеттілігін дамыту, шешендік
                                және пікірталас ережелерін үйрету мен дауларды дипломатиялық жолмен
                                шешу қабілеттерін ояту мақсатында оқу орнында дебаттық қозғалыс
                                құрылды. Дебат барысында өзекті мәселе ұсынылып, оқушылардың өз
                                позицияларын қорғау, қарсылас топқа сұрақ қою дағдылары дамытылады.
                              </div>
                            </div>
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="social_5" className="navtab-content">
                    <div className="columns is-vcentered">
                      <div className="column is-6">
                        <figure className="image">
                          <img className="has-light-shadow" src={social_5} alt="" />
                        </figure>
                      </div>
                      <div className="column is-6">
                        <div className="inner-content">
                          <h2 className="feature-headline is-clean">Адвент күнтізбе</h2>
                          <br />
                          <p className="body-color">
                            Мектебімізде дәстүрлі түрде тақырыптық адвент күнтізбе жасалып,
                            тапсырмалары беріледі. Оқушылар өз бетімен тапсырмаларды орындап,
                            нәтижесі бойынша достарымен бөлісіп, қорытынды жасайды. Бұл күнтізбе
                            арнайы мерекелер алдында және «Өзге елге саяхат» жобасына қатысты
                            жасалады.
                          </p>
                          <div className="solid-list">
                            <div className="solid-list-item">
                              <div className="list-text body-color"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialPage;
