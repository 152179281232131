import errorImage from "../../resources/img/other/error.svg";

const ErrorPage = () => {
  return (
    <div className="error">
      <div className="hero is-relative">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column error-wrap error-centered has-text-centered">
                <img src={errorImage} data-extension=".svg" alt="" />
                <div className="error-caption">
                  <h2>Ой, бұл бетті таба алмадық.</h2>
                  <p>Қайталап көріңіз немесе көмек алу үшін веб-сайт әкімшісіне хабарласыңыз.</p>
                  <div className="button-wrap">
                    <a
                      href="/"
                      className="button button-cta btn-outlined is-bold btn-align primary-btn rounded raised"
                    >
                      Басты бетке оралу
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
