import Breadcrumbs from "../../../components/layouts/breadcrumbs/Breadcrumbs";

const Album_2 = () => {
  // Создаем контекст с изображениями из папки
  const images = require.context("../../../resources/img/pages/gallery/album_2", true, /\.(jpg|jpeg|png)$/);
  // Получаем все изображения в виде массива путей
  const imageList = images.keys().map((image) => images(image));

  return (
    <div className="component__wrapper">
      <div className="section gallery-block">
        <div className="container">
          <Breadcrumbs />
          <div className="gallery-block__wrap">
            {imageList.map((image, index) => {
              return (
                <a key={index} className="gallery-block__item" data-fancybox="gallery" data-src={image}>
                  <img src={image} alt={`image-${index}`} />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Album_2;
