const kz = {
  translation: {
    header: {
      menu_1: "Басты бет",
      menu_2: "Мектеп туралы",
      menu_3: "Cаясаттар",
      menu_4: "Галерея",
      menu_5: "Мұғалімдер",
      menu_6: "Cұрақтар",
      menu_7: "Байланыс",
    },
    main: {
      title_1: "ЖШС «Ханзия» \n Дарын инновациялық мектебі",
      description_1:
        "Талғар қаласында 2020 жылдан бастап 1-7 сынып оқушыларын тереңдетілген академиялық біліммен қатар Халықаралық Бакалавриат бағадарламасы IB PYP бойынша зерттеушілік қабілеттері мен мульти-интеллект жобасы бойынша дағдыларды дамыту жолындамыз.",
      title_2: "Неге біздің мектеп?",
      box_title_1: "Мемлекеттік стандарт",
      box_title_2: "3 мезгіл тамақтану",
      box_title_3: "Мульти-интеллект жобасы",
      box_title_4: "Толық күндік мектеп",
      box_text_1: "Оқыту бағдарламасының мемлекеттік стандартқа сәйкестігі",
      box_text_2: `Дұрыс тамақтану \n салауатын ұстанамыз`,
      box_text_3: "Шахмат, домбыра, каратэ, би, робототехника, зерттеу жобалары",
      box_text_4: "Оқушылар 8.00-18.00 дейін білімдерін шыңдайды",
      title_3: "IB PYP кандидат мәртебесі аясында:",
      subtitle_1:
        "IB PYP – бұл 3 жастан 12 жасқа дейінгі оқушылырға арналған Халықаралық Бакалавриат бағдарламасы. Оқыту стандарттары арқылы баланың табиғи қызығушылығын, шығармашылығымен қатар рефлексия қабілетін ынталандырады.",
      subtitle_2: "Бір жылдың ішінде 4-6 зерттеу юнит аясында келесі тақырыптарды қамтиды:",
      box_title_5: "Біз кімбіз?",
      box_title_6: "Біз өзімізді қалай білдіреміз?",
      box_title_7: "Уақыт пен кеңістік аясында қайдамыз?",
      box_title_8: "Әлем қалай құрылған?",
      box_title_9: "Өз-өзімізді қалай ұйымдастырамыз?",
      box_title_10: "Планета – біздің ортақ үйіміз",
      title_4: "Жетістіктер",
      box_title_11: "Высококвалифицированных учителей",
      box_title_12: "Учеников",
      box_title_13: "Довольных родителей",
      box_title_14: "Призеров международных олимпиад",
      youtube_title: "YouTube арнамызға жазылыңыз",
      youtube_description:
        "Бұл арнада мектеп жаңалықтарын, әлеуметтік дағдыны дамытуға арналған видео-жобаларды, концерттер мен арнайы қойылымдарды тамашалай аласыз.",
      youtube_link: "Арнаға өтіңіз",
      title_5: `Жақсы мектеп іздеп жүрсіз бе? \n Құттықтаймыз! \n Сіз ең жақсысын таптыңыз!`,
      subtitle_3: "Нөміріңізді жіберіңіз, біз сізге хабарласамыз",
    },
    about: {
      title_1: "Жарқын өмір - \n",
      title_1_1: "білімді ұрпақ қолында.",
      description_1: "Біз әр оқушының ойын тыңдаймыз. Қолдаймыз. Әлемді өзгертуге үлес қосамыз.",
      title_2: "ІЗДЕНІМПАЗДАР МЕН ЗЕРТТЕУШІЛЕР \n МЕКТЕБІНЕ ҚОШ КЕЛДІҢ!",
      title_3: "Мектеп миссиясы",
      text_1:
        "Біз мектептегі әрбір жеке тұлғаның әлем дамуына өз үлесін қосу үшін зерттеушілік және ізденімпаздық қасиеттерін жетілдіре отырып, өз жолын табуына және саналы тұлға болып қалыптасуына жағдай жасаймыз.",
      title_4: "Мектеп жобалары",
      subtitle_4:
        "1-7 сынып оқушыларының оқыту және жеке тұлға ретінде дамыту бағдарламасы келесі жобаларды қамтиды:",
      box_title_1: "Білім беру жүйесі",
      box_title_2: "Үйірмелер",
      box_subtitle_2: "Мульти-интеллект жобасы.",
      box_title_3: "Әлеуметтік жобалар",
      box_title_4: "Тереңдетілген пәндер",
      box_title_5: "Мульти-интеллект жобасы",
      box_title_6: "DISC методикасы",
      box_title_7: "Әлеуметтік желідегі жобалар",
      box_title_8: "Кітап оқу мәдениеті",
      box_title_9: "Отбасылық марафон",
      box_title_10: "Ынталандыру бағдарламасы",
      box_title_11: "Дарын жұлдыздары",
      box_title_12: "Қорытынды емтихан",
      box_title_13: "Рейтинг",
      box_text_1:
        "Математика және ағылшын тіліндегі Сингапур математикасы, ағылшын тілі, арнайы зерттеушілік бағытында Қазақ әдебиеті мен қазақ тілі пәндері",
      box_text_2:
        "Шахмат, домбыра, каратэ, хореография, «Өзге елге саяхат» презентациялық жобалар, робототехника",
      box_text_3:
        "Жеке қабілетті анықтау методикасы (DISC) арқылы жыл бойы оқушының бойындағы қабілеттерді бақылап, дамытуға жұмыс жасалынады",
      box_text_4: "Small talks, Daryn news, Thinkers time әлеуметтік желідегі жобаларға қатысады",
      box_text_5:
        "Кітап оқу мәдениетін қалыптастыру бағытында жылына кем дегенде 5 көркем әдебиеттерді оқиды",
      box_text_6:
        "Жылына екі рет өтетін «Бизнес» және «Кітап» жәрмеңкесіне, Отбасылық марафондарға қатысады",
      box_text_7: "Жыл бойы ынталандыру бағдарламасының нәтижелеріне театр, мұражайларға барады",
      box_text_8: "Шарықта, Дарын жұлдыздары! – атты жылдық концертке қатысады",
      box_text_9: "Сәуір айында жылдық қорытынды емтихан тапсырып, қосымша жұмыстар жасалынады",
      box_text_10:
        "Оқушының дамуы туралы есептер ата-аналарға жіберіліп, жеке жұмыстар жүргізіледі",
      title_5: "Кәсібилік",
      subtitle_5:
        "Дарын инновациялық мектебінің ұстаздары үнемі даму үстінде. Ізденімпаздық, ұстанымдылық құндылықтарын негізге ала отырып, үздіксіз даму үстінде. Үш жылдың ішінде:",
      teacher_name_1: "Арсен Байтуков:",
      teacher_name_2: "Гулнара Салмен:",
      teacher_name_3: "NGS мектебінің ұйымдастыруымен",
      teacher_name_4: "Ескендір Бестай:",
      teacher_name_5: "Дархан Қожамсейіт",
      teacher_name_6: "«Mugalim Foundation»",
      teacher_name_7: "«Жаңа ұрпақ, жаңашыл мұғалім»",
      teacher_name_8: "Лилия Мухамедшина:",
      teacher_name_9: "«Білімдегі менеджмент»",
      teacher_name_10: "Нидерландының IB координаторы:",
      teacher_name_11: "Петерсон математикасы бойынша 72 сағаттық оқу.",
      teacher_name_12: "Сингапур математикасын:",
      teacher_name_13: "USAID қоры мен «Education Development Fund Kazakhstan»",
      teacher_name_14: "Біліктілікті арттыру курсы “Педагогтің зерттеу қызметі:",
      teacher_text_1: "«Марцано» таксономиясы бойынша сабақ құрастыру семинары.",
      teacher_text_2: "IB бағдарламасын таныстыру, енгізу семинары.",
      teacher_text_3: "«Жаңа ұрпақ» ұстаздар құрылтай- конференциясы",
      teacher_text_4: "«Жеке тұлғаның даму картасы» семинар",
      teacher_text_5: "Дәркенбаева Азиза, Итигулова Гульнур, Канапьянова Әсемгул, Қуанған Әсима.",
      teacher_text_6:
        "Мұғалім жобасы аясында степендианттары 1, 2, 3 курс студенттерімен кездесу. \n Спикер: Енкебаева Гулжанар",
      teacher_text_7:
        "атты қазақ тілі мен әдебиет пәндерінен мұғалімдер семинары Қалымбетова Замира",
      teacher_text_8:
        "«Апгрэйд за 45 минут» Сингапурлық әдіс-тәсілдердің сабақта қолданылуы семинарына барлық мұғалімдер қатысты.",
      teacher_text_9:
        "мемлекеттік стандарттар жүйесі семинары. Жумажанова Жанна, Сапарова Гульнара.",
      teacher_text_10: "Хэлин Тимспен «IB PYP-дегі оқу тәсілдері» семинар",
      teacher_text_11: "Дәркенбаева Азиза, Итигулова Гульнур, Канапьянова Әсемгул, Қуанған Әсима.",
      teacher_text_12:
        "IB координатымен IB бағдарламасындағы юниттер, жоспарлау, сабақ әзірлеу тақырыптарында семинар",
      teacher_text_13:
        "ұйымдастырған «Білім беру саласындағы өзгерістер мен оқытудың жаңа әдістері» конференциясы \n Спикерлер:Жумажанова Жанна, Сапарова Гульнара, Ослякова Екатерина, Әлімқожа Аяжан, Ертайқызы Жанерке.",
      teacher_text_14:
        "Action Research және Lesson study“ тақырыбы, «Назарбаев Зияткерлік Мектептері» ДББҰ Педагогикалық шеберлік орталығы, 80 академиялық сағаты, 21 мұғалім өтті.",
      calendar_title: "Атаулы күндер",
      calendar_subtitle: "Дарын инновациялық мектебі 2023-2024 оқу жылы",
      calendar_text_1: "жыл",
      calendar_text_2: "Кесте",
      calendar_text_3: "Қыркүйек - Желтоқсан",
      calendar_text_4: "Қантар - Мамыр",
      price_title: "Оқу ақысы",
      price_text_1: "айына",
      price_text_2: "тг",
      price_text_3: "ӨТІНІШ ҚАЛДЫРУ",
    },
    politics: {
      title_1: "Мектеп және басқару \n Cаясаты",
      description_1:
        "Білім сапасы – мектеп әкімшілігі мен педагогикалық ұжымның ең маңызды басымдығы.",
      title_2: "Құжаттама",
    },
    gallery: {
      title_1: "Галерея",
      description_1: "Бақытты бала, бақытты отбасы",
      title_2: "Альбомдар",
      album_title_1: "1 Қыркүйек",
      album_title_2: "Шарықта дарын жұлдыздары",
      album_title_3: "Марафон",
      album_title_4: "Соңғы қоңырау",
    },
    teachers: {
      title_1: "Мұғалімдер",
      subtitle_1: "Сапалы білім болашақ ұрпақ үшін.",
      title_2: "Мектеп әкімшілігі",
      title_3: "Біздің мұғалімдер",
      subtitle_3: "Күш — білімде, білім — кітапта.",
    },
    faq: {
      title_1: "Жарқын өмір - \n",
      title_1_1: "білімді ұрпақ қолында.",
      subtitle_1: "Біз әр оқушының ойын тыңдаймыз. Қолдаймыз. Әлемді өзгертуге үлес қосамыз.",
      title_2: "Сұрақ - жауап",
    },
    contacts: {
      title: "Байланыс",
      description: "Сапалы білім болашақ ұрпақ үшін.",
      address: "Адрес",
      phone: "Телефон",
      text_1: "Талғар қ. Лермонтов 56",
    },
    other: {
      title_1: "Жарқын өмір - \n",
      title_1_1: "білімді ұрпақ қолында.",
      subtitle_1: "Біз әр оқушының ойын тыңдаймыз. Қолдаймыз. Әлемді өзгертуге үлес қосамыз.",
      submit: "Өтінім қалдырыңыз",
      download: "Жүктеу",
      ps_1: "Designed and coded with",
      ps_2: "by Akbulak Group.",
      more: "Толығырақ ",
    },
    form: {
      name: "Сіздің атыңыз *",
      phone: "Телефон нөміріңіз *",
      email: "Сіздің Email",
      class: "Қабылдау сыныбы",
      submit: "Жіберу",
      review_title: "Пікіріңізді осында жазыңыз",
      review_description: "Міндетті түрде оқып, жауап береміз.",
      review_type: "Пікіріңіздің түрін таңдаңыз",
      review_type_1: "Шағым",
      review_type_2: "Ұсыныстар",
      review_type_3: "Түсініктеме",
      review_source: "Пікір қалдырушы",
      review_source_1: "Ата-ана",
      review_source_2: "Мұғалім",
      review_text: "Пікірді жазыңыз",
      review_submit: "Пікірді жіберу",
      resume_title: "Түйіндемеңізді жіберіңіз",
      resume_description:
        "Біз сіздің түйіндемеңіз туралы ақпаратты дерекқорымызға қосамыз. Бұл мектебіміздің жұмысқа орналасу мүмкіндігін арттырады.",
      resume_name: "Толық аты-жөніңіз *",
      resume_experience: "Еңбек өтілі *",
      resume_email: "Сіздің Email *",
      resume_salary: "Күтілетін еңбек ақысы *",
      resume_birthday: "Туған күні дд.мм.гггг *",
      resume_link: "Түйіндемеңін сілтемесі*",
      resume_submit: "Түйіндемеңізді жіберу",
      resume_gender: "What's your gender *",
      man: "Ер адам",
      woman: "Әйел адам",
    },
  },
};
export default kz;
