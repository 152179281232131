import axios from "axios";

// https://darynimektebi.linked.kz - рабочий
// tenantId = 22 - рабочий
// https://localhost:44302 - тестовый хост
// tenantId = 2 - тестовый

const _apiBase = "https://api.darynimektebi.kz/api/content/item/";
const _apiPost = "https://darynimektebi.linked.kz/api/services/app/RequestsPub/CreateNewOnlineRequest";
const _apiReview = "https://darynimektebi.linked.kz/api/services/app/RegisterOfReviewsPub/CreateNewReview";
const _apiResume =
  "https://darynimektebi.linked.kz/api/services/app/ResumeRegistersPub/CreateNewResumeRegister";
const _tenantId = 22;

class PostService {
  // Получение контента по URL
  static async getContent(url_end) {
    const response = await axios.get(_apiBase + url_end);
    return response;
  }

  // Функция отправки форм
  static async setRequest(path, data) {
    let urlString = window.location.href;
    let url = new URL(urlString);
    let utmSource = url.searchParams.get("utm_source");
    if (!utmSource) {
      utmSource = url.hostname;
    }
    const response = await axios
      .post(path, { ...data, requestSource: utmSource, tenantId: _tenantId })
      .then((res) => {
        console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });
    return response;
  }

  // Отправка формы заявки
  static async setPost(data) {
    this.setRequest(_apiPost, data);
  }

  // Отправка формы отзыва
  static async setReview(data) {
    console.log(data);
    this.setRequest(_apiReview, data);
  }

  // Отправка формы резюме
  static async setResume(data) {
    console.log(data);
    this.setRequest(_apiResume, data);
  }
}

export default PostService;
