import Breadcrumbs from "../../../components/layouts/breadcrumbs/Breadcrumbs";

const Calendar_2023 = () => {
  return (
    <div className="section calendar">
      <div className="container">
        <Breadcrumbs />
        <div className="calendar__block">
          <div id="flex-card" className="section-title-wrapper has-text-centered">
            <h2 className="title section-title has-text-centered dark-text text-bold">
              Атаулы күндер 2023 оқу жылы
            </h2>
          </div>
          <div className="columns is-vcentered pt-30">
            <div className="column is-10 is-offset-1">
              <table className="responsive-table is-accent">
                <tbody>
                  <tr>
                    <th className="dark-text">Кыркуйек</th>
                    <th>Күні</th>
                    <th>Іс шара</th>
                    <th>Жауаптылар</th>
                  </tr>
                  <tr>
                    <td data-th="Кыркуйек"></td>
                    <td data-th="Күні">01.09</td>
                    <td data-th="Іс шара">Білім күні</td>
                    <td data-th="Жауаптылар">6 сынып</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">22.09</td>
                    <td data-th="Іс шара">Жәрмеңке</td>
                    <td data-th="Жауаптылар">Ағылшын кафедрасы</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні"></td>
                    <td data-th="Іс шара">ТЖ жаттығулар</td>
                    <td data-th="Жауаптылар">Сынып жетекшілер</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="dark-text">Қазан</th>
                    <th>Күні</th>
                    <th>Іс шара</th>
                    <th>Жауаптылар</th>
                  </tr>
                  <tr>
                    <td data-th="Қазан"></td>
                    <td data-th="Күні">02-03.10</td>
                    <td data-th="Іс шара">Видео-сьемка(Әдістер)</td>
                    <td data-th="Жауаптылар">Мұғалімдер</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">05.10</td>
                    <td data-th="Іс шара">Мұғалімдер күні</td>
                    <td data-th="Жауаптылар">4-сынып</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">14.10</td>
                    <td data-th="Іс шара">Family and marathon project</td>
                    <td data-th="Жауаптылар">5-сынып</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">20.10</td>
                    <td data-th="Іс шара">Challenge Көркемсөз</td>
                    <td data-th="Жауаптылар">Тілдер кафедрасы</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">27.10</td>
                    <td data-th="Іс шара">Өзге елге саяхат</td>
                    <td data-th="Жауаптылар">Барлық сынып</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="dark-text">Қараша</th>
                    <th>Күні</th>
                    <th>Іс шара</th>
                    <th>Жауаптылар</th>
                  </tr>
                  <tr>
                    <td data-th="Қараша"></td>
                    <td data-th="Күні"></td>
                    <td data-th="Іс шара">Шарықта Дарын жұлдыздары дайындық</td>
                    <td data-th="Жауаптылар"></td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="dark-text">Желтоқсан</th>
                    <th>Күні</th>
                    <th>Іс шара</th>
                    <th>Жауаптылар</th>
                  </tr>
                  <tr>
                    <td data-th="Желтоқсан"></td>
                    <td data-th="Күні">09.12</td>
                    <td data-th="Іс шара">Шарықта Дарын жұлдыздары дайындық - 2</td>
                    <td data-th="Жауаптылар"></td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">15.12</td>
                    <td data-th="Іс шара">Тәуелсіздік күніне тәрбие сағаты</td>
                    <td data-th="Жауаптылар">Барлық мұғалімдер</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">27-28.12</td>
                    <td data-th="Іс шара">Жаңа жыл</td>
                    <td data-th="Жауаптылар">4,7 сыныптар</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar_2023;
