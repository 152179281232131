import background from "../../resources/img/other/background.jpg";
import { useTranslation } from "react-i18next";

const FAQ = () => {
  const { t } = useTranslation();
  return (
    <div className="faq">
      <div
        className="hero parallax is-cover is-relative is-default is-bold"
        data-background={background}
        data-color="#000"
        data-color-opacity="0.3"
      >
        <div id="main-hero" className="hero-body pt-20 pb-20">
          <div className="container has-text-centered">
            <div className="columns is-vcentered is-centered">
              <div className="column is-8 has-text-centered is-header-caption">
                <h1 className="title is-spaced main-title is-2">
                  <span className="color__prime">{t("other.title_1")}</span>
                  {t("other.title_1_1")}
                </h1>
                <h2 className="subtitle is-5 light-text">{t("other.subtitle_1")}</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section is-medium">
        <div className="container">
          <div className="section-title-wrapper">
            <h2 className="title section-title has-text-centered dark-text text-bold">
              {t("faq.title_2")}
            </h2>
          </div>
          <div className="faq-wrapper">
            <div className="columns">
              <div className="column is-5 is-offset-1">
                <div className="faq-block">
                  <div className="block-header">
                    <div className="header-icon">
                      <i className="fa fa-plus"></i>
                    </div>
                    <h3>Бір сыныпта қанша оқушы оқиды?</h3>
                  </div>
                  <div className="block-body">
                    <p>20 оқушы</p>
                  </div>
                </div>
                <div className="faq-block">
                  <div className="block-header">
                    <div className="header-icon">
                      <i className="fa fa-plus"></i>
                    </div>
                    <h3>Мектепті көруге болады?</h3>
                  </div>
                  <div className="block-body">
                    <p>Тек сенбі күндері. Администратор арқылы алдын-ала жазылу керек</p>
                  </div>
                </div>
                <div className="faq-block">
                  <div className="block-header">
                    <div className="header-icon">
                      <i className="fa fa-plus"></i>
                    </div>
                    <h3>Қосымша сабақтар, үйірмелер бар ма?</h3>
                  </div>
                  <div className="block-body">
                    <p>
                      Қосымша сабақтар және үйірмелер сабақ кестесіне кірістірілген. Барлық
                      сыныптарда 6-8 түрлі үйірмелер өтеді.
                    </p>
                  </div>
                </div>
                <div className="faq-block">
                  <div className="block-header">
                    <div className="header-icon">
                      <i className="fa fa-plus"></i>
                    </div>
                    <h3>Мектепте баланың күн тәртібі қалай өтеді?</h3>
                  </div>
                  <div className="block-body">
                    <p>
                      Оқушылар 7.30 – 18.00 аралығында толық күн мектепте өткізеді. Күніне – 8-9
                      сабақтар қарастырылған. Әр сабақтан соң 10-минуттық демалыс уақыты бар. Түскі
                      уақытта 20-30 минут далаға шығады. Негізгі пәндер мен мульти-интеллект
                      үйірмелері араластырып, оқушылар шаршамау үшін баланспен қойылған. Жұма
                      күндері – сабақтар 16.00 дейін болады.
                    </p>
                  </div>
                </div>
              </div>
              <div className="column is-5">
                <div className="faq-block">
                  <div className="block-header">
                    <div className="header-icon">
                      <i className="fa fa-plus"></i>
                    </div>
                    <h3>Неше жастан балаларды қабылдайсыздар?</h3>
                  </div>
                  <div className="block-body">
                    <p>6 жастан</p>
                  </div>
                </div>
                <div className="faq-block">
                  <div className="block-header">
                    <div className="header-icon">
                      <i className="fa fa-plus"></i>
                    </div>
                    <h3>Мектепке түсу үшін арнайы емтихан тапсырады ма?</h3>
                  </div>
                  <div className="block-body">
                    <p>
                      Иә, математика, қазақ тілі және ағылшын тілдерінен емтихан тапсырады. Білім
                      көрсеткіші – 80%-дан асу керек. Тапсыра алмаған жағдайда, бір айдан соң ғана
                      қайтадан тапсыра алады.
                    </p>
                  </div>
                </div>
                <div className="faq-block">
                  <div className="block-header">
                    <div className="header-icon">
                      <i className="fa fa-plus"></i>
                    </div>
                    <h3>Мектеп формасы бар ма?</h3>
                  </div>
                  <div className="block-body">
                    <p>
                      Мектеп формасы - ақ түсті поло-жейделері, көк немесе қоңыр түсті шалбар,
                      белдемшелер ретінде бекітілген. қарастырылған.
                    </p>
                  </div>
                </div>
                <div className="faq-block">
                  <div className="block-header">
                    <div className="header-icon">
                      <i className="fa fa-plus"></i>
                    </div>
                    <h3>Үй жұмысы беріледі ма?</h3>
                  </div>
                  <div className="block-body">
                    <p>
                      Демалыс күндеріне беріледі. Оған қоса қушының қызығушылығына, сабақтарды игеру
                      көрсеткіштеріне қарай жүйелі түрде беріледі.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
