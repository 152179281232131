// Погружаем скрипты шаблона Bulkit с папки public
const BulkitScripts = (destroy) => {
  // При размонтировании компонента
  if (destroy) {
    document.querySelectorAll("script[src*='/js/test/']").forEach((script) => {
      script.remove();
    });
    // console.log("Удаление скриптов при размонтировании компонента");
    return;
  }

  // Функция для подключения скрипта
  const loadScript = (src, async = true) => {
    const script = document.createElement("script");
    script.src = src;
    script.async = async;
    document.body.appendChild(script);

    return new Promise((resolve, reject) => {
      script.onload = () => resolve();
      script.onerror = () => reject();
    });
  };
  loadScript(process.env.PUBLIC_URL + "/js/test/app.js")
    .then(() => {
      // console.log("appScript скрипт загружен");
      return loadScript(process.env.PUBLIC_URL + "/js/test/core.js");
    })
    .then(() => {
      // console.log("coreScript скрипт загружен");
    })
    .catch(() => {
      // console.error("Ошибка при загрузке скриптов");
    });
};

export default BulkitScripts;
