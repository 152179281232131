import { useTranslation } from "react-i18next";

import Breadcrumbs from "../../../components/layouts/breadcrumbs/Breadcrumbs";

import about_11 from "../../../resources/img/pages/teachers/all.jpeg";

const ProfessionalsPage = () => {
  const { t } = useTranslation();
  return (
    <div className="professionals about__child">
      <div className="section">
        <div className="container">
          <Breadcrumbs />
          <div className="section-title-wrapper has-text-centered mb-40">
            <div className="bg-number"></div>
            <div className="centered-title">
              <h2 className="section-title-landing">{t("about.title_5")}</h2>
              <div className="title-divider"></div>
              <div className="subheading">{t("about.subtitle_5")}</div>
            </div>
          </div>
          <div className="centered-title"></div>
          <div className="columns is-vcentered is-multiline pb-60">
            <div className="column is-8 is-offset-2 is-relative is-centered-portrait pt-40">
              <img src={about_11} alt="" />
            </div>
            <div className="column is-4 ">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_1")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_1")}</p>
            </div>
            <div className="column is-4">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_2")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_2")}</p>
            </div>
            <div className="column is-4 ">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_3")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_3")}</p>
            </div>
            <div className="column is-4">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_4")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_4")}</p>
            </div>
            <div className="column is-4 ">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_5")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_5")}</p>
            </div>
            <div className="column is-4">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_6")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_6")}</p>
            </div>
            <div className="column is-4 ">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_7")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_7")}</p>
            </div>
            <div className="column is-4">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_8")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_8")}</p>
            </div>
            <div className="column is-4 ">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_9")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_9")}</p>
            </div>
            <div className="column is-4">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_10")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_10")}</p>
            </div>
            <div className="column is-4 ">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_11")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_11")}</p>
            </div>
            <div className="column is-4">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_12")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_12")}</p>
            </div>
            <div className="column is-6">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_14")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_14")}</p>
            </div>
            <div className="column is-6 ">
              <div className="side-title mb-10">
                <h3 className="title is-5">{t("about.teacher_name_13")}</h3>
              </div>
              <p className="side-paragraph is-size-6">{t("about.teacher_text_13")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalsPage;
