import Breadcrumbs from "../../../components/layouts/breadcrumbs/Breadcrumbs";

const Calendar_2024 = () => {
  return (
    <div className="section">
      <div className="container">
        <Breadcrumbs />
        <div className="calendar__block">
          <div id="flex-card" className="section-title-wrapper has-text-centered">
            <h2 className="title section-title has-text-centered dark-text text-bold">
              Атаулы күндер 2024 оқу жылы
            </h2>
          </div>
          <div className="columns is-vcentered pt-30">
            <div className="column is-10 is-offset-1">
              <table className="responsive-table is-accent">
                <tbody>
                  <tr>
                    <th className="dark-text">Қантар</th>
                    <th>Күні</th>
                    <th>Іс шара</th>
                    <th>Жауаптылар</th>
                  </tr>
                  <tr>
                    <td data-th="Қантар"></td>
                    <td data-th="Күні">19.01</td>
                    <td data-th="Іс шара">Директорлық бақылау жұмысы</td>
                    <td data-th="Жауаптылар">Математика кафедрасы</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="dark-text">Ақпан</th>
                    <th>Күні</th>
                    <th>Іс шара</th>
                    <th>Жауаптылар</th>
                  </tr>
                  <tr>
                    <td data-th="Ақпан"></td>
                    <td data-th="Күні">05-08.02</td>
                    <td data-th="Іс шара">1 тур олимпиада</td>
                    <td data-th="Жауаптылар">Барлық мұғалімдер</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">05-08.02</td>
                    <td data-th="Іс шара">Математика, ағылшын, жаратылыстану</td>
                    <td data-th="Жауаптылар">1-4 класс</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">05-08.02</td>
                    <td data-th="Іс шара">Математика, ағылшын, тарих</td>
                    <td data-th="Жауаптылар">5-7 класс</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">12-15.02</td>
                    <td data-th="Іс шара">2 тур олимпиада</td>
                    <td data-th="Жауаптылар">Барлық мұғалімдер</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">23.02</td>
                    <td data-th="Іс шара">Challenge Көркемсөз</td>
                    <td data-th="Жауаптылар">Тілдер кафедрасы</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="dark-text">Наурыз</th>
                    <th>Күні</th>
                    <th>Іс шара</th>
                    <th>Жауаптылар</th>
                  </tr>
                  <tr>
                    <td data-th="Наурыз"></td>
                    <td data-th="Күні">05-8.03</td>
                    <td data-th="Іс шара">8 Наурыз</td>
                    <td data-th="Жауаптылар">Барлық сынып</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="dark-text">Сәуір</th>
                    <th>Күні</th>
                    <th>Іс шара</th>
                    <th>Жауаптылар</th>
                  </tr>
                  <tr>
                    <td data-th="Сәуір"></td>
                    <td data-th="Күні">05.04</td>
                    <td data-th="Іс шара">Наурыз мерекесі</td>
                    <td data-th="Жауаптылар">1-2 сыныптар</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">18.04</td>
                    <td data-th="Іс шара">Емтихан</td>
                    <td data-th="Жауаптылар">1-7 сынып</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">23.04</td>
                    <td data-th="Іс шара">Challenge Көркемсөз</td>
                    <td data-th="Жауаптылар">Тілдер кафедрасы</td>
                  </tr>
                </tbody>
                <tbody>
                  <tr>
                    <th className="dark-text">Мамыр</th>
                    <th>Күні</th>
                    <th>Іс шара</th>
                    <th>Жауаптылар</th>
                  </tr>
                  <tr>
                    <td data-th="Мамыр"></td>
                    <td data-th="Күні">09.05</td>
                    <td data-th="Іс шара">Жеңіс күні</td>
                    <td data-th="Жауаптылар">6 сынып</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">18.05</td>
                    <td data-th="Іс шара">Family and marathon project</td>
                    <td data-th="Жауаптылар">3 сынып</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">25.05</td>
                    <td data-th="Іс шара">Соңғы қоңырау</td>
                    <td data-th="Жауаптылар">Барлық сынып</td>
                  </tr>
                  <tr>
                    <td data-th=""></td>
                    <td data-th="Күні">25.05</td>
                    <td data-th="Іс шара">Кітаптар жәрмеңкесі</td>
                    <td data-th="Жауаптылар">Кітапханашылар</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Calendar_2024;
