const en = {
  translation: {
    header: {
      menu_1: "Home",
      menu_2: "About",
      menu_3: "Politics",
      menu_4: "Gallery",
      menu_5: "Teachers",
      menu_6: "FAQ",
      menu_7: "Contacts",
    },
    main: {
      title_1: "LLP 'Khanzia' Daryn Innovative School",
      description_1:
        "In the city of Talgar, starting from 2020, we are on the way to develop research abilities and multi-intelligence project skills for students of grades 1-7, along with in-depth academic education, according to the International Baccalaureate Program IB PYP.",
      title_2: "Why our school?",
      box_title_1: "State standard",
      box_title_2: "3 meals a day",
      box_title_3: "Multi-intelligence projects",
      box_title_4: "Full-day school",
      box_text_1: "Alignment with the state standards of the educational program",
      box_text_2: "Ensuring proper nutrition and health",
      box_text_3: "Chess, dombra, karate, bi, robotics, research projects",
      box_text_4: "Students expand their knowledge from 8:00 to 18:00",
      title_3: "IB PYP",
      subtitle_1:
        "IB PYP is the International Baccalaureate Program for students aged 3 to 12. Through teaching standards, it develops the child's natural curiosity, creativity, and reflective skills.",
      subtitle_2: "Within one year, 4-6 units of inquiry cover the following topics:",
      box_title_5: "Who are we?",
      box_title_6: "How do we express ourselves?",
      box_title_7: "Where we are in place and time?",
      box_title_8: "How the world works?",
      box_title_9: "How we organize ourselves?",
      box_title_10: "The planet - our shared home",
      title_4: "Achievements",
      box_title_11: "Highly qualified teachers",
      box_title_12: "Students",
      box_title_13: "Satisfied parents",
      box_title_14: "Winners of international competitions",
      youtube_title: "Subscribe to our YouTube channel",
      youtube_description:
        "Lorem ipsum dolor sit amet, vim quidam blandit voluptaria no, has eu lorem convenire incorrupte. Vis mutat altera percipit ad, ipsum prompta ius eu. Sanctus appellantur.",
      youtube_link: "Subscribe",
      title_5: `Are you looking for a good school? \n Congratulations! \n You found the best!`,
      subtitle_3: "Send us your number, we will contact you",
    },
    about: {
      title_1: "CORRECT INVESTMENTS IN THE FUTURE OF CHILDREN",
      description_1: "From the very first day. Unlocking potential. Shaping the future.",
      title_2: "Future School",
      title_3: "School Mission",
      text_1:
        "We foster the development of each individual student's global competence, cultivating their uniqueness, guiding them to find their own path, and contributing to the formation of a conscious and creative personality.",
      title_4: "School Programs",
      subtitle_4:
        "We offer the following programs for students in grades 1-7 as part of individualized teaching and personal development:",
      box_title_1: "Educational System",
      box_title_2: "Workshops",
      box_subtitle_2: "Multi-intelligence program.",
      box_title_3: "Social Projects",
      box_title_4: "In-depth Subjects",
      box_title_5: "Multi-intelligence Program",
      box_title_6: "DISC Methodology",
      box_title_7: "Community Projects",
      box_title_8: "Reading Culture",
      box_title_9: "Family Marathon",
      box_title_10: "Integration Program",
      box_title_11: "Star of Knowledge",
      box_title_12: "Final Exam",
      box_title_13: "Rating",
      box_text_1:
        "Kazakh literature and language, English language, and special research-based programs in mathematics and Kazakh literature.",
      box_text_2:
        "Chess, dombyra, karate, choreography, special projects like 'Journey to Another Country,' and robotics.",
      box_text_3:
        "The DISC methodology identifies and develops the student's abilities throughout the year.",
      box_text_4:
        "Participation in social projects such as Small Talks, Daryn News, and Thinkers Time.",
      box_text_5:
        "Formation of a reading culture, with students reading at least 5 fiction books a year.",
      box_text_6:
        "Participation in annual business and book marathons, as well as family marathons.",
      box_text_7: "Participation in the results of the Integration Program in theater and murals.",
      box_text_8: "Performance in the annual 'Stars of Daryn!' concert.",
      box_text_9:
        "Taking the annual comprehensive exam in May and engaging in additional projects.",
      box_text_10:
        "Parents receive essays about their child's development, and individual projects are carried out.",
      title_5: "Professionalism",
      subtitle_5:
        "Teachers at Daryn Innovative School are highly committed to personal development, innovation, and excellence. Within three years:",
      teacher_name_1: "Arsen Baytukov:",
      teacher_name_2: "Gulnara Salman:",
      teacher_name_3: "With the organization of the NGS school",
      teacher_name_4: "Eskendir Bestay:",
      teacher_name_5: "Darkhan Kozhamseit",
      teacher_name_6: "Mugalim Foundation",
      teacher_name_7: "New Horizon, Innovative Teacher",
      teacher_name_8: "Liliya Mukhamedshina:",
      teacher_name_9: "Educational Management",
      teacher_name_10: "Coordinator of IB in the Netherlands:",
      teacher_name_11: "72-hour course in Peterson's mathematics.",
      teacher_name_12: "In Singapore mathematics:",
      teacher_name_13: "USAID and 'Education Development Fund Kazakhstan'",
      teacher_text_1: "Workshop on lesson planning using the Marzano taxonomy.",
      teacher_text_2: "Introduction to the IB program, integration seminars.",
      teacher_text_3: "Conference 'New Horizon' for the organization of teachers.",
      teacher_text_4: "Seminar 'Map of the development of an individual student.'",
      teacher_text_5: "Darkenbayeva Aziza, Itigulova Gulnur, Kanapyanova Asemgul, Kuangan Asem.",
      teacher_text_6:
        "Interaction with students majoring in teaching in the framework of the teacher project. Speaker: Enkebaeva Gulzhanar.",
      teacher_text_7:
        "Seminars by teachers of the subjects 'Kazakh language and literature.' Zhumazhanova Zhamira.",
      teacher_text_8:
        "Participation of all teachers in the seminar 'Upgrade in 45 minutes' on the use of Singapore methods in the lesson.",
      teacher_text_9:
        "Seminar on the state standards system. Zhumazhanova Zhanna, Saparova Gulnara.",
      teacher_text_10: "Seminar by Helen Timspen 'Teaching methods in IB PYP.'",
      teacher_text_11: "Darkenbayeva Aziza, Itigulova Gulnur, Kanapyanova Asemgul, Kuangan Asem.",
      teacher_text_12:
        "Seminar with the coordinator of IB in the implementation of units, planning, and preparation of lessons.",
      teacher_text_13:
        "Organized the conference 'Changes in the field of education and new teaching methods.' Speakers: Zhumazhanova Zhanna, Saparova Gulnara, Oslyakova Ekaterina, Alimkhozha Aizhan, Ertaikyzy Zhanerke.",
      calendar_title: "Key Dates",
      calendar_subtitle: "Daryn Innovative School 2023-2024 Academic Year",
      calendar_text_1: "year",
      calendar_text_2: "Schedule",
      calendar_text_3: "September - December",
      calendar_text_4: "January - March",
      price_title: "Tuition Fee",
      price_text_1: "per month",
      price_text_2: "KZT",
      price_text_3: "PAYMENT DETAILS",
    },
    politics: {
      title_1: "School and Management \n Policy",
      description_1:
        "Educational quality is the most important priority of school administration and pedagogical team.",
      title_2: "Documentation",
    },
    gallery: {
      title_1: "Gallery",
      description_1: "Happy child, happy family",
      title_2: "Albums",
      album_title_1: "1st September",
      album_title_2: "Stars in the Sky Concert",
      album_title_3: "Marathon",
      album_title_4: "Latest Achievements",
    },
    teachers: {
      title_1: "Teachers",
      subtitle_1: "Quality education for the future.",
      title_2: "Leadership",
      title_3: "Our Teachers",
      subtitle_3: "Strength is in knowledge, knowledge is in books.",
    },
    faq: {
      title_1: "FAQ",
      subtitle_1: "Quality education for the future.",
      title_2: "Q&A",
    },
    contacts: {
      title: "Contacts",
      description: "Quality education for a bright future.",
      address: "Address",
      phone: "Phone",
      text_1: "Talgar, Lermontov St., 56",
    },
    other: {
      submit: "Submit your application",
      download: "Download",
      ps_1: "Designed and coded with",
      ps_2: "by Akbulak Group.",
      more: "More ",
    },
    form: {
      name: "Your name *",
      phone: "Your phone number *",
      email: "Your Email",
      class: "Enrollment class",
      submit: "Submit",
      review_title: "Submit your opinion",
      review_description: "We highly value your opinion.",
      review_type: "Choose type of feedback",
      review_type_1: "complaint",
      review_type_2: "request",
      review_type_3: "comments",
      review_source: "Choose source of feedback",
      review_source_1: "parent",
      review_source_2: "class teacher",
      review_text: "Write your feedback",
      review_submit: "Submit feedback",
      resume_title: "Submit your resume",
      resume_description:
        "Submit information about yourself to our database. This increases the chances of finding a job at our school.",
      resume_name: "Your full name *",
      resume_experience: "Your work experience *",
      resume_email: "Your Email *",
      resume_salary: "Expected monthly salary *",
      resume_birthday: "Date of birth dd.mm.yyyy *",
      resume_link: "Link to resume *",
      resume_submit: "Submit resume",
      resume_gender: "What's your gender *",
      man: "Male",
      woman: "Female",
    },
  },
};
export default en;
